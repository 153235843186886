import React from 'react'
import { Container, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import Example from '../component/RecentNews'

export default function TrainingContainer() {

    const [data, setData] = React.useState([]);
    const [keys, setKeys] = React.useState([]);
    const [isLoaded, setIsLoaded] = React.useState(false)

    React.useEffect(() => {
        fetch(`/data/training.json`)
            .then((resp) => resp.json())
            .then((resp) => {
                setData(resp);
                setKeys(Object.keys(resp))
                setIsLoaded(true)
            })
    }, [])
    return (
        <div>
            <Example bread={`Home / Training`} />
            {isLoaded && <Container style={{ padding: '20px' }}>
            <div style={{ marginBottom: '20px', backgroundColor: '#f5f5f5', padding: '10px', borderLeft: `solid 1px lightblue` }}>
          
            <Table size="small">
                
                <TableBody>
                <TableRow>
                        <TableCell>2017-21 Industrial Internship</TableCell>
                        <TableCell><a target="_blank" href={`/PDFs/2017-21_Industrial_Internship.pdf`}>View</a></TableCell>
                       
                    </TableRow>
                    <TableRow>
                        <TableCell>2018-22 Industrial Internship</TableCell>
                        <TableCell><a target="_blank" href={`/PDFs/2018-22_Industrial_Internship.php`}>View</a></TableCell>
                       
                    </TableRow>
                    <TableRow>
                        <TableCell>2019-23 Batch Academic Intership</TableCell>
                        <TableCell><a target="_blank" href={`/PDFs/2019-23_Batch_Academic_Intership.pdf`}>View</a></TableCell>
                       
                    </TableRow>
                    <TableRow>
                        <TableCell>2019-23 Batch Social Internship</TableCell>
                        <TableCell><a target="_blank" href={`/PDFs/2019-23_Batch_Social_Internship.pdf`}>View</a></TableCell>
                       
                    </TableRow>
                    <TableRow>
                        <TableCell>2019-23 Industrial Internship</TableCell>
                        <TableCell><a target="_blank" href={`/PDFs/2019-23_Industrial_Internship.pdf`}>View</a></TableCell>
                       
                    </TableRow>
                    <TableRow>
                        <TableCell>2020-24 Batch Academic Internship</TableCell>
                        <TableCell><a target="_blank" href={`/PDFs/2020-24_Batch_Academic_Internship.pdf`}>View</a></TableCell>
                       
                    </TableRow>
                    <TableRow>
                        <TableCell>2020-24 Batch Society Internship</TableCell>
                        <TableCell><a target="_blank" href={`/PDFs/2020-24_Batch_Society_Internship.pdf`}>View</a></TableCell>
                       
                    </TableRow>
                </TableBody>
            </Table>
</div>

                {Array.isArray(keys) && keys.map((el, index) =>

                    <div key={index} style={{ marginBottom: '20px', backgroundColor: '#f5f5f5', padding: '10px', borderLeft: `solid 1px lightblue` }}>
                        <Typography variant="h6" style={{ fontWeight: 900 }} gutterBottom>{el}</Typography>
                        <ShowTable
                            data={data[el]}
                        />

                        <br />
                    </div>

                )}

            </Container>}
        </div>
    )
}


function ShowTable({ data }) {
    return (
        <div style={{overflowX: 'scroll'}}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Roll No</TableCell>
                        <TableCell>Organisation</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {Array.isArray(data) && data.map((el, index) =>
                        <TableRow key={index}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{el.name}</TableCell>
                            <TableCell>{el.roll_no}</TableCell>
                            <TableCell>{el.org}</TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )
}
