import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'
import ComingSoon from './ComingSoon'
import { makeStyles, withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

const styles = (theme) => ({
  div: {
    textAlign: 'center',
  },
})

class ResearchScholars extends Component {
  render() {
    let { classes } = this.props
    return (
      <div>
        <Example bread={`Home / Research Scholars`} />

        <MDBContainer style={{ paddingTop: '30px' }}>
          <TitleStyle
            title={`Research Scholars`}
          />
          <br />



          <table className="table table-bordered table-sm" cellPadding={1} style={{ width: "100%" }}>
  <tbody>
    <tr>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.64cm",
            marginRight: "0.26cm",
            textIndent: "-0.3cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            <strong>Research Guide</strong>
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.88cm",
            textIndent: "-0.4cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            <strong>Name</strong>
            <strong>of</strong>
            <strong>&nbsp;</strong>
            <strong>the&nbsp;</strong>
            <strong>Scholar</strong>
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.66cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            <strong>Topic</strong>
            <strong>of the</strong>
            <strong>Research</strong>
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "1.67cm",
            textIndent: "-0.68cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            <strong>University</strong>
            <strong>&amp;</strong>
            <strong>Year</strong>
            <strong>&nbsp;</strong>
            <strong>of&nbsp;</strong>
            <strong>Registration</strong>
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.57cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            <strong>Status</strong>
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td
        rowSpan={9}
        style={{  }}
      >
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginTop: "0.17cm"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.67cm",
            marginRight: "0.26cm",
            textIndent: "-0.39cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Dr.Navajit Saikia
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.33cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            GunajitKalita
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginRight: "0.33cm",
            marginTop: "0.1cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Synthesisof&nbsp;Reversible Logic Circuit
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.1cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            GauhatiUniversity
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            YoR-2014,Reg&nbsp;No-Engg.25/14
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.33cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Completed
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Gautam Chakravarty
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Automatic Speech Recognition: Human ComputerInterface&nbsp;for
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Sylhetilanguage
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            GauhatiUniversity
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            YoR-2014,Reg&nbsp;No-Engg.29/14
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginTop: "0.23cm"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Completed
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.23cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            AmlanJyoti Das
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Featuresand&nbsp;Detectorsfor Video Surveillance
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            GauhatiUniversity
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            YoR-2014,Reg.&nbsp;No-ECE-11/14
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.23cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Completed
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Simantika Choudhury
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Video Surveillance using Learning&nbsp;basedtechniques
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            GauhatiUniversity
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            YoR-2018,Reg.&nbsp;No-ECE-02/18
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.23cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            On&nbsp;going
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.45cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            AparajitaDas
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Human Detection &amp; Action Recognition&nbsp;forSurveillance
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Applications
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginRight: "2.25cm",
            marginTop: "0.22cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            GauhatiUniversity YoR-2018
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.45cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            On&nbsp;going
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.23cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Lakhyadeep Konwar
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            HandGesture Recognition
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginRight: "0.33cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            forVirtual&nbsp;Reality Application
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginRight: "2.25cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            GauhatiUniversity YoR-2019,
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Reg.No-&nbsp;PA-181-839-0001
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            On&nbsp;going
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginTop: "0.23cm"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            JumileeGogoi
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginRight: "0.33cm",
            marginTop: "0.45cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            ReversibleDigital&nbsp;Logic Design
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            AssamScience&nbsp;&amp;Technology University
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Reg.No-&nbsp;006506222,
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Year2022
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginTop: "0.23cm"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            On&nbsp;going
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.45cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            NelsonVarte
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Computer Vision based Animal&nbsp;Detectionusing&nbsp;Deep
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            LearningModels
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginRight: "0.01cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            AssamDon&nbsp;BoscoUniversity Reg. No- DU2022PHD0052
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Year2022
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.45cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            On&nbsp;going
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            ViolinaDeka
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.23cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Designof&nbsp;PowerElectronic Device
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            AssamScience&nbsp;&amp;Technology University
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Reg.No-&nbsp;A/F,Year&nbsp;2023
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            On&nbsp;going
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td
        rowSpan={4}
        style={{  }}
      >
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginTop: "0.25cm"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginRight: "0.26cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Dr. Rashi Borgohain
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginRight: "0.74cm",
            marginTop: "0.45cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            GolamImran Hussain
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.45cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Detectionof&nbsp;Pesticidesin&nbsp;tea leaves using nanomaterials
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            AssamScience&nbsp;&amp;Technology University
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Reg.No-&nbsp;006606222
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Year2022
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            On&nbsp;going
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginTop: "0.23cm"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            KishorDas
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Gassensing&nbsp;using nanomaterials
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            AssamScience&nbsp;&amp;Technology University
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Reg.No-&nbsp;007306222
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Year2022
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            On&nbsp;going
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginTop: "0.23cm"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            PrasenjitSaha
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginTop: "0cm"
          }}
        >
          <br />
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginRight: "1.26cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            SemiconductorDevice Modelling&nbsp;usingTCAD
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            AssamScience&nbsp;&amp;Technology University
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Reg.No-&nbsp;005606222,
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Year2022
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            On&nbsp;going
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.23cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Ruchira Mazumdar
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Designof&nbsp;Microstrip&nbsp;patch
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginRight: "0.33cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            antenna using Nanomaterials
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            AssamScience&nbsp;&amp;Technology University
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Reg.No-&nbsp;A/F,Year&nbsp;2023
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            On&nbsp;going
          </span>
        </p>
      </td>
    </tr>
    <tr>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginRight: "0.58cm",
            marginTop: "0.45cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Dr. Bijoy Goswami
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm",
            marginTop: "0.45cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Ayan Bhattacharya
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Modeling and Simulation of FET structure for
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            improvementin&nbsp;Automobile Industry
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            NIT&nbsp;Silchar
          </span>
        </p>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            Reg.No-&nbsp;A/F,Year&nbsp;2024
          </span>
        </p>
      </td>
      <td style={{  }}>
        <p
          style={{
            marginBottom: "0cm",
            lineHeight: "100%",
            textAlign: "left",
            background: "transparent",
            marginLeft: "0.19cm"
          }}
        >
          <span size={2} style={{ fontSize: 15 }}>
            On going
          </span>
        </p>
      </td>
    </tr>
  </tbody>
</table>








        </MDBContainer>
      </div>
    )
  }
}

export default withStyles(styles)(ResearchScholars)
