import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'

import { withStyles  } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const styles = (theme) => ({
    div: {
        textAlign: 'center',
    },
})

class CollaborationsContainer extends Component {

    state = {
        data: [],
        isLoaded: false,
        openModal: false
    }

    componentDidMount() {
        GetData(`/getcollaborations-website`)
            .then((resp) => {
                this.setState({
                    data: resp,
                    isLoaded: true
                })
            })
    }

    render() {
        let { classes } = this.props
        return (
            <div>
                <Example bread={`Home / Collaborations`} />

                <MDBContainer style={{ paddingTop: '30px' }}>
                    <TitleStyle
                        title={`Collaborations`}
                    />
                    <br />


                    {this.state.isLoaded && <div>

                        <Table>

                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 700, color: 'black' }}>#</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: 'black' }}>Type of Collaboration</TableCell>

                                    <TableCell style={{ fontWeight: 700, color: 'black' }}>Agency</TableCell>
                                    <TableCell style={{ fontWeight: 700, color: 'black' }}>File</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(this.state.data) && this.state.data.map((el, index) => <TableRow key={index}>

                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{el.type}</TableCell>
                                    <TableCell>{el.agency}</TableCell>
                                    <TableCell><Button color="primary" onClick={() => this.setState({
                                        openModal: true
                                    })}>Request File Access</Button></TableCell>
                                </TableRow>)}
                            </TableBody>


                        </Table>
                    </div>}






                    <Dialog
        open={this.state.openModal}
        keepMounted
        onClose={() => this.setState({
            openModal: false
        })}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Request File Access"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            You donot have access to this file. you need to ask the department for access through email at <a href="mailto:head.ete@aec.ac.in">head.ete@aec.ac.in</a>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          
          <Button onClick={() => this.setState({
            openModal: false
        })} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

                </MDBContainer>
            </div>
        )
    }
}

export default withStyles(styles)(CollaborationsContainer)
