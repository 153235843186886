import React, { Component } from 'react'
import Gallery from 'react-grid-gallery';
import {Grid} from '@material-ui/core'
import Example from '../component/RecentNews';
import TitleStyle from '../component/TitleStyle';
import { Container } from '@material-ui/core';
import { PostData } from '../api/service';



export default class PictureGallaryContainer extends Component {

  state = {
    files: [],
    isLoaded: false,
    isDownloading: false
  }
  componentDidMount() {
    // fetch files with code AC

    PostData(`/getfilestoresbytype`, { type: 'GALLERY' })
      .then((resp) => {
        //console.log(resp)
        this.setState({
          files: resp,
          isLoaded: true
        })
      })
  }


  arrangeFiles = (photos) => {
    let pp = [];
    if(Array.isArray(photos)){
      photos.map((el,index) => {
        pp.push({
          src: el.url,
          thumbnail: el.url,
          thumbnailWidth: 320,
          thumbnailHeight: 174,
          caption: el.name
        })
      })
    }

    return pp;
  }

  render() {
    return (
      <div style={{  }}>
        <Example bread={`Home / Gallery`} />

        <Container>
          <br />
          <TitleStyle
            title={`Gallery`}
          />

          <br />
          {this.state.isLoaded && <div style={{display: "flex", flexDirection: "column"}}>
            {Array.isArray(this.state.files) && this.state.files.map((el, index) =>
              <div key={index} style={{}}>
                <div style={{fontWeight: 700, fontSize: 16, marginBottom: 15, marginTop: 15}}>{el.name}</div>
                {/* <Grid container spacing={2}>
                  {Array.isArray(el.file) && el.file.map((el1,index1) => <Grid key={index1} item xs="12" sm="12" md={"3"} lg={3}>
                      <img
                        src={el1.url}
                        style={{width: "100%", objectFit: "cover", height: 300}}
                       />
                    </Grid>)}
                </Grid> */}
                <Gallery images={this.arrangeFiles(el.file)} />
              </div>
            )}
          </div>}

        </Container>
      </div>
    )
  }
}
