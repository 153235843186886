import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'
import ComingSoon from './ComingSoon'
import { makeStyles, withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

const styles = (theme) => ({
  div: {
    textAlign: 'center',
  },
})

class Committee extends Component {
  render() {
    let { classes } = this.props
    return (
      <div>
        <Example bread={`Home / Committees`} />

        <MDBContainer style={{ paddingTop: '30px' }}>
       
          

        <>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>DEPARTMENTAL ADVISORY BOARD (DAB)</strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Principal, Assam Engineering College
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Chairman</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Prof. Prabin K. Bora, EEE, IITG
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>External Academician</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Prof. Sunandan Baruah, ECE, DBCET Guwahati
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              External Academician/ Alumnus
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Shri Prabir K. Das, Director STPI, Guwahati
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Corporate representatives
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Shri Abhijit Bhuyan, MD, Bohniman Systems
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Industrial representatives/Alumnus
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Shri Tanmoy Goswami, Director, Sumato Global Tech.
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Industrial Representatives
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          colSpan={2}
          style={{ background: "transparent", verticalAlign: "top" }}
        >
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              All faculty members of the Department of Electronics &amp;
              Telecommunication Engg.
            </span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>DEPARTMENTAL UNDERGRADUATE PROGRAM COMMITTEE</strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Head of the Department&nbsp;
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Chairperson</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>D.S. Pegu</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Rashi Borgohain</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Bijoy Goswami</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Mr. Niranjan Jyoti Borah
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Secretary</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Ananya Choudhury</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Mrs. Swapna Bharali</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td
          colSpan={2}
          style={{ background: "transparent", verticalAlign: "top" }}
        >
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              One student each from 2<sup>nd</sup>, 3<sup>rd</sup> and 4
              <sup>th</sup> year Engineering Students.
            </span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>DEPARTMENTAL PHD MONITORING COMMITTEE</strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Head of the Department&nbsp;
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Chairperson</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Rashi Borgohain</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Secretary</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Bijoy Goswami</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Ananya Choudhury</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>QUALITY CONTROL AND ACCREDITATION COMMITTEE</strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Head of the Department&nbsp;
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Chairperson</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Rashi Borgohain</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Secretary</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Bijoy Goswami</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Mr. Niranjan Jyoti Borah
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Ananya Choudhury</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Mrs. Swapna Bharali</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>TIME-TABLE COORDINATION COMMITTEE</strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Rashi Borgohain</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Bijoy Goswami</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Mr. Niranjan Jyoti Borah
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Ananya Choudhury</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>STUDENT MENTORING COMMITTEE</strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Head of the Department
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Chairperson</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>D.S. Pegu</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Rashi Borgohain</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Coordinator 1<sup>st</sup> year&nbsp;
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Dr. Bijoy Goswami&nbsp;
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Coordinator 2<sup>nd</sup> Year
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Ananya Choudhury</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Coordinator 3<sup>rd</sup> Year
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Niranjan Jyoti Borah</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Coordinator 4<sup>th</sup> Year&nbsp;
            </span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Mrs. Swapna Bharali</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>FACULTY ADVISORY COMMITTEE</strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Principal, AEC</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Chairperson</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Head of ETE Dept., AEC
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Secretary</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Head of CSE Dept., AEC
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Head of Electronics Communication, GU
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>DEPARTMENTAL EVENT ORGANIZATION COMMITTEE</strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Head of the Department&nbsp;
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Chairperson</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Rashi Borgohain</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Coordinator</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Bijoy Goswami</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Coordinator</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Niranjan Jyoti Borah</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Coordinator</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Ananya Choudhury</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Mrs. Swapna Bharali</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>DEPARTMENT OUTREACH COORDINATION COMMITTEE</strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Bijoy Goswami</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Coordinator</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Mr. Niranjan Jyoti Borah&nbsp;
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Coordinator</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Ananya Choudhury</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Coordinator</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>
        INDUSTRY- INSTITUTE COORDINATION AND COOPERATION COMMITTEE
      </strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Head of the Department
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Chairperson</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Bijoy Goswami</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Coordinator</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Mr. Niranjan Jyoti Borah
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Co-Coordinator</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>
        DEPARTMENT INFRASTRUCTURE DEVELOPMENT AND MONITORING COMMITTEE
      </strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Head of the Department
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Rashi Borgohain</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Bijoy Goswami</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Mr. Niranjan Jyoti Borah
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>DEPARTMENT LIBRARY COMMITTEE</strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Riju Kalita</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Secretary</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Mr. Niranjan Jyoti Borah&nbsp;
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Ananya Choudhury</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Mrs. Swapna Bharali</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>DEPARTMENT WEBSITE COMMITTEE</strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Bijoy Goswami</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Secretary</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Mr. Niranjan Jyoti Borah&nbsp;
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Coordinator&nbsp;</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Mrs. Riju Kalita (CSE)
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>DEPARTMENTAL SANITIZATION AND HYGIENE COMMITTEE</strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Rashi Borgohain</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Secretary</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Ananya Choudhury</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Coordinator&nbsp;</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Mr. Gagan Kalita (Lab)
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Mr. Pranjal Deka (office)
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <span style={{ color: "rgb(0, 0, 0)" }}>
      <strong>DIFFERENTIALLY ABLE STUDENT MONITORING COMMITTEE</strong>
    </span>
  </p>
  <table className="table table-bordered table-sm">
    <tbody>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>
              Mr. Niranjan Jyoti Borah
            </span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Secretary</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Mrs. Swapna Bharali</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
      <tr>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Dr. Ananya Choudhury</span>
          </p>
        </td>
        <td style={{ background: "transparent" }}>
          <p
            style={{
              marginBottom: "0.25cm",
              lineHeight: "115%",
              textAlign: "left",
              background: "transparent"
            }}
          >
            <span style={{ color: "rgb(0, 0, 0)" }}>Member</span>
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
  <p
    style={{
      marginBottom: "0.28cm",
      lineHeight: "108%",
      textAlign: "left",
      background: "transparent"
    }}
  >
    <br />
    &nbsp;
  </p>
</>


             
          




        </MDBContainer>
      </div>
    )
  }
}

export default withStyles(styles)(Committee)