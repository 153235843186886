import React, { Component } from "react";
import Example from "../component/RecentNews";
import { MDBContainer, MDBBtn, Button } from "mdbreact";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import TitleStyle, { SecondaryStyle } from "../component/TitleStyle";
import { GetData, PostData } from "../api/service";
import Gallery from "react-grid-gallery";
import { Grid } from "@material-ui/core";

export default class AchievementsContainer extends Component {
  state = {
    tableData: [],
    tableData1: [],
    isLoaded: false,
    files: [],
    isFilesLoaded: false,
  };
  componentDidMount() {
    GetData("/getawards").then((resp) => {
      if (Array.isArray(resp)) {
        this.setState({
          tableData: resp,
          isLoaded: true,
        });
      }
    });

    PostData(`/getfilestoresbytype`, { type: "STUDENT_ACHIVEMENT" }).then(
      (resp) => {
        console.log(resp);
        this.setState({
          files: resp,
          isFilesLoaded: true,
        });
      }
    );
  }

  arrangeFiles = (photos) => {
    let pp = [];
    if (Array.isArray(photos)) {
      photos.map((el, index) => {
        pp.push({
          src: el.url,
          thumbnail: el.url,
          thumbnailWidth: 320,
          thumbnailHeight: 174,
          caption: el.name,
        });
      });
    }

    return pp;
  };
  render() {
    return (
      <div>
        <Example bread={`Home / Awards & Achievements`} />
        <MDBContainer style={{ padding: "20px" }}>
          <div style={{ overflowX: "scroll" }}>
            <div>
              <p lang="en-US">
                <span lang="en-IN">
                  <strong>PARTICIPATION IN OVERSEAS INTERNSHIP</strong>
                </span>
              </p>
              <center>
                <table
                  class="table table-sm table-bordered table-striped"
                  width="100%"
                  cellSpacing={0}
                  cellPadding={7}
                >
                  <tbody>
                    <tr>
                      <td>
                        <p lang="en-US">
                          <strong>Sl. No</strong>
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          <strong>Name of the student</strong>
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          <strong>Name of the Institute</strong>
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          <strong>Duration</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">1</p>
                      </td>
                      <td>
                        <p lang="en-US">Chinmoyee Deka</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Saint Petersburg State University, Russia
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">One month</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">2</p>
                      </td>
                      <td>
                        <p lang="en-US">Rajarsi Goswami</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          The Pennsylvania State University, USA
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">One month</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </center>
              <p lang="en-US">
                <span lang="en-IN">
                  <strong>PARTICIPATION IN NATIONAL LEVEL COMPETITION </strong>
                </span>
              </p>
              <center>
                <table
                  width="100%"
                  class="table table-sm table-bordered table-striped"
                  cellSpacing={0}
                  cellPadding={7}
                >
                  <tbody>
                    <tr>
                      <td>
                        <p lang="en-US">
                          <strong>Participants</strong>
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          <strong>Event</strong>
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          <strong>Date</strong>
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          <strong>Performance</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Devraj Kashyap</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          India Quiz organised by Azim Premji University
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">08/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">3rd</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Ashique Anowar, Ritav Kashyap,Rohan Verma
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Smart India Hackathon organised by Ministry of
                          Education, MoE's Innovation Cell, AICTE, Persistent,
                          Inter Institutional Inclusive Innovations Center
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">08/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">Grand Finalists</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Anirban Biswas Ankit Barua</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Technoxian-World Robotics Competition organised by All
                          India Council for Robotics and Automation and
                          Department of Science &amp;Technology, Ministry of
                          Science &amp; Technology, Government of India
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">08/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">Winners, 1 st position</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Proyash Paban Sarma Borah, Arindam Bhowal, Devraj
                          Kayship, Rohan Verma
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Un-flood Assam organised by Assam Startup and Ministry
                          of Electronics and Information technology
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">July 2022</p>
                      </td>
                      <td>
                        <p lang="en-US">1st</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Devraj Kashyap</p>
                      </td>
                      <td>
                        <p lang="en-US">Earth Day Quiz organised by GU</p>
                      </td>
                      <td>
                        <p lang="en-US">06/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">1st</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Devraj Kashyap</p>
                      </td>
                      <td>
                        <p lang="en-US">Chem Quiz organised by AEC</p>
                      </td>
                      <td>
                        <p lang="en-US">06/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">2nd</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Devraj Kashyap</p>
                      </td>
                      <td>
                        <p lang="en-US">River Talks organised by RGU</p>
                      </td>
                      <td>
                        <p lang="en-US">06/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">3rd</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Ritav Kashyap, Ashique Anowar, Proyash Paban Sarma
                          Borah, Devraj Kashyap
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Technophilia Robo Sumo organised by RGU
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">06/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">2nd</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Anirban Biswas, Naveed Islam, Ankit Barua
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Technophilia Robo Sumo organised by RGU
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">06/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">1st</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Ashique Anowar, Proyash Paban Sarma Borah, Ritav
                        </p>
                        <p lang="en-US">Kashyap, Devraj Kashyap</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Technophilia Robo Soccer organised by RGU
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">06/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">2nd</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Ankit Barua, Anirban Biswas, Naveed Islam,
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Technophilia Robo Soccer organised by RGU
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">06/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">1st</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Ashique Anowar, Proyash Paban Sarma Borah, Devraj
                          Kashyap,
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Techniche Escalade organised by IITG</p>
                      </td>
                      <td>
                        <p lang="en-US">06/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">First</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Proyash Paban Sarma Borah, Arindam Bhowal
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          AppVenture AppDev competition organised by GIMT
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">May 2022</p>
                      </td>
                      <td>
                        <p lang="en-US">1st</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Ritav Kashyap, Ashique Anowar, Naveed Islam
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Techmilap Robo War organised by GIMT</p>
                      </td>
                      <td>
                        <p lang="en-US">05/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">2nd</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Ankit Barua, Devraj Kashyap, Anirban Biswas,Proyash
                          Paban Sarma Borah,
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Techmilap organised by GIMT</p>
                      </td>
                      <td>
                        <p lang="en-US">05/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">1st</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Ankit Barua, Devraj Kashyap, Anirban Biswas,
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Manifest Kommercio Robo War organised by GU
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">05/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">2nd</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Ashique Anowar, Proyash Paban Sarma Borah, Ritav
                          Kashyap, Naveed Islam
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Manifest Kommercio Robo War organised by GU
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">05/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">1st</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Devraj Kashyap, Anirban Biswas, Naveed Islam,
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Techtrix Robo Sumo organised by GEC</p>
                      </td>
                      <td>
                        <p lang="en-US">04/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">2nd</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Ashique Anowar, Proyash Paban Sarma Borah, Ritav
                          Kashyap, Ankit Barua
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Techtrix Robo Sumo organised by GEC</p>
                      </td>
                      <td>
                        <p lang="en-US">04/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">First</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Ritav Kashyap, Devraj Kashyap</p>
                      </td>
                      <td>
                        <p lang="en-US">R AEC LFR organised by AEC</p>
                      </td>
                      <td>
                        <p lang="en-US">03/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">1st</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Devraj Kashyap, Naveed Islam, Ankit Barua
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">R AEC Robo Soccer organised by AEC</p>
                      </td>
                      <td>
                        <p lang="en-US">03/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">2nd</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Ritav Kashyap, Anirban Biswas</p>
                      </td>
                      <td>
                        <p lang="en-US">R AEC Robo Soccer organised by AEC</p>
                      </td>
                      <td>
                        <p lang="en-US">03/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">1st</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Ankit Barua, Devraj Kashyap, Naveed Islam
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">R AEC robo sumo organised by AEC</p>
                      </td>
                      <td>
                        <p lang="en-US">03/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">1st</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Devraj Kashyap, Ashique Anowar, Proyash Paban Sarma
                          Borah
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Techfest Meshmerize organised by IIT Bombay
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">02/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">4th</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Anirban Biswas, Ritav Kashyap</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Techfest Meshmerize organised by IIT Bombay
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">02/2022</p>
                      </td>
                      <td>
                        <p lang="en-US">2nd</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Arindam Bhowal, Hrishikesh Deuri, Rakon Mazumder (5th
                          sem) and Akash Chetia (5th sem)
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Diamond Jublee Hackathon organised by Jorhat
                          Engineering College
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">October 2021</p>
                      </td>
                      <td>
                        <p lang="en-US">3rd</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Hrishikesh Deuri</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          CSS Battle organised by Thadomal Sahani Engineering
                          College, Bombay
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">09/2021</p>
                      </td>
                      <td>
                        <p lang="en-US">2nd</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Devraj Kashyap, Anirban Biswas, Naveed Islam, Ankit
                          Barua
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Udbhawan Xutra organised by JEC</p>
                      </td>
                      <td>
                        <p lang="en-US">09/2021</p>
                      </td>
                      <td>
                        <p lang="en-US">Third</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Trilosan Boro</p>
                      </td>
                      <td>
                        <p lang="en-US">Ideathon 2021 by Aec Coding Club</p>
                      </td>
                      <td>
                        <p lang="en-US">15-06-2021</p>
                      </td>
                      <td>
                        <p lang="en-US">Best Aecian</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Rohit Shah</p>
                      </td>
                      <td>
                        <p lang="en-US">Ideathon 2021</p>
                      </td>
                      <td>
                        <p lang="en-US">16-06-2021</p>
                      </td>
                      <td>
                        <p lang="en-US">Best AECian team</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Proyash Paban Sarma Borah</p>
                      </td>
                      <td>
                        <p lang="en-US">Escalade 10. under Techniche IITG</p>
                      </td>
                      <td>
                        <p lang="en-US">04-09-2021</p>
                      </td>
                      <td>
                        <p lang="en-US">Rs 25000</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Naveed Islam</p>
                      </td>
                      <td>
                        <p lang="en-US">UDBHAWAN XUTRA</p>
                      </td>
                      <td>
                        <p lang="en-US">26-09-2021</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Certification and cash award for 3rd prize worth
                          ₹5000+₹5000 for project making
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Hrishikesh Deuri</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          DR. MADHAB CHANDRA BORA MEMORIAL HACKATHON (JEC)
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">02-10-2021</p>
                      </td>
                      <td>
                        <p lang="en-US">3rd position</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Hrishikesh Deuri</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          All India CSS Battle organised by Thadomal Sahani
                          Engineering College, Bombay
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">05-09-2021</p>
                      </td>
                      <td>
                        <p lang="en-US">2nd Position</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Devraj Kashyap</p>
                      </td>
                      <td>
                        <p lang="en-US">Escalade</p>
                      </td>
                      <td>
                        <p lang="en-US">04-09-2021</p>
                      </td>
                      <td>
                        <p lang="en-US">First prize with Rs 25 thousand</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Devraj kashyap</p>
                      </td>
                      <td>
                        <p lang="en-US">Udbhawan xutra</p>
                      </td>
                      <td>
                        <p lang="en-US">26-09-2021</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          3rd prize, comprising certificate and rs5000
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Ashique Anowar</p>
                      </td>
                      <td>
                        <p lang="en-US">Escalade Mains, IITG</p>
                      </td>
                      <td>
                        <p lang="en-US">06-09-2021</p>
                      </td>
                      <td>
                        <p lang="en-US">Twenty five thousand Rupees</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Arindam Bhowal</p>
                      </td>
                      <td>
                        <p lang="en-US">JEC hackathon</p>
                      </td>
                      <td>
                        <p lang="en-US">26-09-2021</p>
                      </td>
                      <td>
                        <p lang="en-US">3rd position - 10k as reward</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Ankit Barua</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Udbhawan Xutra: All Assam inter engineering college
                          technical model making competition
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">27-12-2021</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          3rd position, A certificate and cash prize of rs.5000
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Anirban Biswas</p>
                      </td>
                      <td>
                        <p lang="en-US">UDBHAWAN XUTRA</p>
                      </td>
                      <td>
                        <p lang="en-US">26-09-2021</p>
                      </td>
                      <td>
                        <p lang="en-US">5k+ certificate</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Akash Chetia</p>
                      </td>
                      <td>
                        <p lang="en-US">JEC Hackathon</p>
                      </td>
                      <td>
                        <p lang="en-US">02-10-2021</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          3rd Position in software solution to problem statement
                          plagiarism checker
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Swandeep Sarmah</p>
                      </td>
                      <td>
                        <p lang="en-US">India Mobile Congress 2020</p>
                      </td>
                      <td>
                        <p lang="en-US">08/12/2020 to 10/12/2020</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Top 18 out of 133 submissions for Agritech and
                          Livestock.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Saurav Bharali</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Robo-War and Robo-Soccer competitions in EUPHISM 2020
                          organized by GIMT
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">05/03/2020 to 07/03/2020</p>
                      </td>
                      <td>
                        <p lang="en-US">1st Runners up</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Amartya Ranjan Saikia</p>
                        <p lang="en-US">Joy Dutta</p>
                        <p lang="en-US">Priyangshu Yogi</p>
                        <p lang="en-US">Sandeep Talukdar</p>
                        <p lang="en-US">Pulkit Singh</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          “BUILD FOR DIGITAL INDIA” under initiative of MEITY
                          and Google
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">02/02/2020 to 04/02/2020</p>
                      </td>
                      <td>
                        <p lang="en-US">Selected at Zonal level</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Swandeep Sarmah</p>
                        <p lang="en-US">Sweta Saha</p>
                        <p lang="en-US">Abhilasha Barman</p>
                      </td>
                      <td>
                        <p lang="en-US">AICTE VISHWAKARMA AWARD 2019</p>
                      </td>
                      <td>
                        <p lang="en-US">22/09/2019</p>
                      </td>
                      <td>
                        <p lang="en-US">Winners of Zonal convention</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Rajarshi Das</p>
                        <p lang="en-US">Roshan Ojha</p>
                      </td>
                      <td>
                        <p lang="en-US">Hackathon at IITG</p>
                      </td>
                      <td>
                        <p lang="en-US">26/10/2019</p>
                      </td>
                      <td>
                        <p lang="en-US">2nd runners up</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Saurab Bharali</p>
                      </td>
                      <td>
                        <p lang="en-US">AEC PSG Hackathon 2019</p>
                      </td>
                      <td>
                        <p lang="en-US">13/09/2019 to 14/09/2019</p>
                      </td>
                      <td>
                        <p lang="en-US">1st runners up</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Bhaskar Borah</p>
                      </td>
                      <td>
                        <p lang="en-US">AEC PSG Hackathon 2019</p>
                      </td>
                      <td>
                        <p lang="en-US">13/09/2019 to 14/09/2019</p>
                      </td>
                      <td>
                        <p lang="en-US">2nd runners up</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Abhiruchi Bora</p>
                      </td>
                      <td>
                        <p lang="en-US">AEC PSG Hackathon 2019</p>
                      </td>
                      <td>
                        <p lang="en-US">13/09/2019 to 14/09/2019</p>
                      </td>
                      <td>
                        <p lang="en-US">2nd runners up</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Ursula Das</p>
                        <p lang="en-US">Chinmoyee Deka</p>
                        <p lang="en-US">Diksha Deb</p>
                        <p lang="en-US">Mamata Roy</p>
                        <p lang="en-US">Prerana Mazumdar</p>
                      </td>
                      <td>
                        <p lang="en-US">NE Hackathon 2019</p>
                      </td>
                      <td>
                        <p lang="en-US">03/09/2019 to 04/09/2019</p>
                      </td>
                      <td>
                        <p lang="en-US">Best Women’s team award</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Manash Pratim Pathak</p>
                      </td>
                      <td>
                        <p lang="en-US">Build-to-last 2019 IITG</p>
                      </td>
                      <td>
                        <p lang="en-US">31/08/2019</p>
                      </td>
                      <td>
                        <p lang="en-US">1st Prize</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Dakshee Lahkar</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Student Partnership Program of Bolt IoT
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">26/08/2019 to 27/08/2019</p>
                      </td>
                      <td>
                        <p lang="en-US">Stipend of Rs 1500</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Ritik Kumar Jain</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          NPTEL course on “Introduction to Machine Learning-IIT
                          Kharagpur”
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Jul-Sep 2019</p>
                      </td>
                      <td>
                        <p lang="en-US">Successfully completed</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Sweta Saha</p>
                      </td>
                      <td>
                        <p lang="en-US">Python for Data Science – Online</p>
                      </td>
                      <td>
                        <p lang="en-US">Jul-Nov 2019</p>
                      </td>
                      <td>
                        <p lang="en-US">Elite</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Manisha Das</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          NPTEL course on “Programming, Data Structures And
                          Algorithms Using Python”
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Jul-Sep 2019</p>
                      </td>
                      <td>
                        <p lang="en-US">Elite</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Bishal Das</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          NPTEL course on “Programming, Data Structures And
                          Algorithms Using Python”
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Jul-Sep 2019</p>
                      </td>
                      <td>
                        <p lang="en-US">Elite</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Himakshi Devi</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          NPTEL course on “Programming, Data Structures And
                          Algorithms Using Python”
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Jul-Sep 2019</p>
                      </td>
                      <td>
                        <p lang="en-US">Elite</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Mamata Roy</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          NPTEL course on “Programming, Data Structures And
                          Algorithms Using Python”
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Jul-Sep 2019</p>
                      </td>
                      <td>
                        <p lang="en-US">Elite</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Nilanjan Chatterjee</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          NPTEL course on “An Introduction To Programming
                          Through C++ - Online”
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Jul-Nov 2019</p>
                      </td>
                      <td>
                        <p lang="en-US">Elite+Silver</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Ursula Das</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          NPTEL course on “Programming, Data Structures And
                          Algorithms Using Python”
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Jul-Sep 2019</p>
                      </td>
                      <td>
                        <p lang="en-US">Elite+Silver</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Dakshee Lahkar</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Scilab Textbook Companion Project of IIT Bombay
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">02/07/2018 to 03/10/2018</p>
                      </td>
                      <td>
                        <p lang="en-US">Honorarium of Rs. 9,900</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Hardik Das</p>
                        <p lang="en-US">Shruti Goswami</p>
                        <p lang="en-US">Namrata Bora</p>
                        <p lang="en-US">Brijesh Khemka</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Idea Generation Competition in AmazingNano 2019
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">2/03/2019</p>
                      </td>
                      <td>
                        <p lang="en-US">1st Position</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Joy Dutta</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Smart India Hackathon 2018 organised by GoI
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">30/03/2018 to 31/03/2018</p>
                      </td>
                      <td>
                        <p lang="en-US">KPIT innovation award</p>
                        <p lang="en-US">
                          (most technical and innovative project)
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Sumit K Banik</p>
                        <p lang="en-US">Subham Pareek</p>
                        <p lang="en-US">Suman Bhargav</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Ideathon 2017 held at IIT Delhi organised by GoI
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">27/10/2017</p>
                      </td>
                      <td>
                        <p lang="en-US">2nd Runner-Up</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </center>
              <p lang="en-US">
                <span lang="en-IN">
                  <strong>Student paper presentation (Conference)</strong>
                </span>
              </p>
              <center>
                <table
                  width="100%"
                  class="table table-sm table-bordered table-striped"
                  cellSpacing={0}
                  cellPadding={7}
                >
                  <tbody>
                    <tr>
                      <td colSpan={4}>
                        <p lang="en-US">
                          <strong>International</strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          <strong>Participants</strong>
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          <strong>Paper Title</strong>
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          <strong>Presented At </strong>
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          <strong>Date </strong>
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Rajarshi Das, Roshan Kumar Ojha, Dipam Tamuli, Swapnil
                          Bhattacharjee and Niranjan Jyoti Borah,
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Hand Gesture-Based Recognition System for
                          Human-Computer Interaction
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          International Conference on Machine Vision &amp;
                          Augmented Intelligence (MAI 2022)
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          04-07, March 2022 hosted by NIT Jamshedpur.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Ursula Das, Neha Spriha Baruah, Bishal Das, Rashi
                          Borgohain
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Fruit Disease Detection using Image Processing
                        </p>
                        <p lang="en-US">Techniques</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          AICTE sponsored International Conference on Advances
                          in
                        </p>
                        <p lang="en-US">
                          Computer Engineering &amp; Communication Technology
                          (ICACET-2021)
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          22nd -23rd October 2021 and received Best Paper Award.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Hardik Das, Shruti Goswami, Namrata Borah, Rashi
                          Borgohain
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          IoT Based Water Supply Monitoring and Notification
                        </p>
                        <p lang="en-US">System</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          National Conference on Recent Trends in Electronics
                          and Communication Engineering – 2020
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">14/08/2020</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Atryee Bhuyan, Manisha Das, Abhishek Tamuli, Subham
                          Chakrabarty, S. Sarma
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Implementation of 128-bit AES</p>
                        <p lang="en-US">
                          Algorithm using Xilinx System Generator
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">NCECC 2020, NIT Jamshedpur,</p>
                      </td>
                      <td>
                        <p lang="en-US">5-6 Sept., 2020</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Ankur Jyoti Sarmah, Samujjal Das, Bhasbyab Jyoti
                          Deka,Himsikhar Gayan, Mrinmoy Kalita
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">Implementation of Smart City Apects</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          National Conference on Recent Trends in Electronics
                          and Communication Engineering-2020
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">14/08/2020</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          P. Gogoi, J. Dutta, R. Matam and M. Mukherjee
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          An UAV assisted Multi-Sensor based Smart Parking
                          System
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          IEEE INFOCOM 2020 - IEEE Conference on Computer
                          Communications Workshops (INFOCOM WKSHPS), Toronto,
                          ON, Canada,
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">6-9 July 2020</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Shivam Dutta, D. Sivraj, D. Venkata Sai Joga Rao, S.
                          Hemanth Kumar, Krthikeyan S. Raghul K
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          IoT based vehicle accident detection and dynamic alert
                          system for two-wheelers
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          iicsc2019: IEEE sponsored Indo-Israel International
                          Joint Conference on Sustainable Cities-2019
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">29/01/2020 to 31/01/2020</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Shivam Dutta, D. Venkata Sai Joga Rao, S. Hemanth
                          Kumar, Nishant Bharti
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          An embedded system to display and control of air
                          quality inside a public transport
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">MSSND 2019</p>
                      </td>
                      <td>
                        <p lang="en-US">27/12/2019</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Shivam Dutta, D. Sivraj, D. Venkata Sai Joga Rao, S.
                          Hemanth Kumar, Kasturi Kalita
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          Development of Air Quality Monitoring and Notification
                          System
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">I3SET2K19</p>
                      </td>
                      <td>
                        <p lang="en-US">13/12/2019 to 14/12/2019</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">Ursula Das</p>
                        <p lang="en-US">Prerna Mazumdar</p>
                      </td>
                      <td>
                        <p lang="en-US">Smart LED based Streetlight System</p>
                      </td>
                      <td>
                        <p lang="en-US">
                          IEEE Workshop on Recent Advances in Photonics (WRAP)
                          2019
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">13/12/2019 to 14/12/2019</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p lang="en-US">
                          Poulamita Paul Choudhury, Rajlakshmi Das, Mridul Jyoti
                          Roy, Rashi Borgohain
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          <a href="https://ieeexplore.ieee.org/abstract/document/8724055/">
                            A brief introduction to High Availability Seamless
                            Redundancy (HSR) and some of its drawbacks: An
                            insight into the functioning of HSR Protocol
                          </a>
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">
                          3<sup>rd</sup> International Conference on
                          Communication and Electronics Systems (ICCES)
                        </p>
                      </td>
                      <td>
                        <p lang="en-US">15/10/2018</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </center>
            </div>

            {/* <SecondaryStyle
              title={`Faculty / Student`}
            /> */}
          </div>
          <div>
            {this.state.isFilesLoaded && (
              <Grid container spacing={2}>
                {Array.isArray(this.state.files) &&
                  this.state.files.map((el, index) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={3}
                      lg={3}
                      key={index}
                      style={{}}
                    >
                      <div
                        style={{
                          fontWeight: 700,
                          fontSize: 16,
                          marginBottom: 15,
                          marginTop: 15,
                        }}
                      >
                        {el.name}
                      </div>

                      <Gallery images={this.arrangeFiles(el.file)} />
                    </Grid>
                  ))}
              </Grid>
            )}
          </div>
        </MDBContainer>
      </div>
    );
  }
}

const TableStyle = (props) => {
  return (
    <div>
      <MDBTable responsive>
        <MDBTableHead>
          <tr>
            <th>#</th>
            <th>Faculty/Student</th>
            <th>Name</th>
            <th>Award</th>
            <th>Year</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {props.data.map((el, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{el.type}</td>
              <td>{el.name}</td>
              <td>{el.award}</td>
              <td>{el.year}</td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>

      <div align="right">
        <Button size="sm" color="primary">
          Click to get More
        </Button>
      </div>
    </div>
  );
};
