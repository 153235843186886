import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, Button } from "mdbreact";
import './css/about.css';
import HomePageNews from './News/HomePageNews';
import PictureGallaryContainer from '../containers/PictureGallaryContainer';
import TitleStyle from './TitleStyle';
import RichText from './admin/RichText';
import { blue } from '@material-ui/core/colors';
import { Badge, Grid, Typography } from '@material-ui/core';
import UpcomingEvents from './Events/UpcomingEvents';

const gridExamplesPage = () => {
  return (
    <MDBContainer>
      <MDBRow class="w-100">
        <MDBCol lg="8" sm="12" md="8" xs="12" className="p-3">
          <div>
            <TitleStyle
              title={`ABOUT THE DEPARTMENT`}
            />
            <p align="justify" style={{ fontSize: '1.1em' }}>
              The department of Electronics & Telecommunication Engineering was established in the year 1983. Presently, it offers Bachelor of Technology (B.Tech.) course in Electronics & Telecommunication Engineering with an annual intake capacity of 60. The department has been always attracting quality students through the Common Entrance Examination conducted by the Government of Assam. The faculties of the department have a wide variety of expertise with a strong academic background. It has been always an endeavour for the teachers of this department to impart quality knowledge to the students such that as an engineer they can contribute accountably to the technological world as well as for the betterment of society. Keeping in mind the great potential and demand for new communication technologies, the syllabi of the B. Tech course try to cover all major thrust areas in Electronics and Communication Technology and also in the allied fields. The graduates of the department are placed in various organizations across the globe and making the department proud.
            </p>

            <br />


            <TitleStyle
              title={`HISTORY OF THE DEPARTMENT`}
            />
            <p align="justify" style={{ fontSize: '1.1em' }}>
              Established in 1983 with an annual intake of 15 students, it is the first in this field to provide the Bachelor of Engineering (B.E.) degree in Electronics & Telecommunication Engineering under the Director of Technical Education, Govt of Assam. The department is affiliated with Gauhati University prior to getting affiliated with Assam Science and Technology University (ASTU) in the year 2017.  The annual intake of the department increased to 30 in the year 1996 and finally a total of 60 students in 2007. Continuing with the ICT roadmap, the department started its Ph.D. program in 2014.  The department offers a syllabus covering the major thrust areas in the field of Electronics and Telecommunication.
            </p>

            <br />

            <TitleStyle
              title={`VISION`}
            />
            <p align='justify' style={{ fontSize: '1.1em' }}>
              To be a center for quality education in Electronics and Communication Engineering.
            </p>
          </div>

          <br />

          <div>
            <TitleStyle
              title={`MISSION`}
            />
            <p align="justify" style={{ fontSize: '1.1em', paddingLeft: '20px' }}>
              <ul>
                <li>
                  To impart quality education for producing skilled manpower in electronics and communication engineering to cater for the need of the country and society as a whole.
                </li>
                <li>
                  To encourage students and faculty for research and innovation in electronics and communication engineering through industry-academia interactions.
                </li>
                <li>
                  To facilitate an environment for shared learning, teamwork, development of professional skills, and preparation for higher education.
                </li>
                <li>
                  To promote human values and ethics for a sustainable environment and society
                </li>

              </ul>
            </p>
          </div>

          <br />

          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={12} md={6} lg={6}>
              <TitleStyle
                title={`CONTACT`}
              />
              <p  style={{ fontSize: '1.1em' }}>
                <strong>Dr. Navajit Saikia</strong><br />
                Head of The Department<br />
                Department of Electronics and Telecommunication Engineering, <br />
                 Assam Engineering College, Jalukbari Guwahati-781013<br /> 
                Email: head.ete@aec.ac.in
              </p>
            </Grid> */}
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <TitleStyle
                title={`CONTACT ADDRESS OFFICE`}
              />
              <p  style={{ fontSize: '1.1em' }}>
                
                Department of Electronics and Telecommunication Engineering <br />
                Assam Engineering College, Jalukbari, Guwahati-781013<br />
                Assam, India<br />
                Email: head.ete@aec.ac.in
                
              </p>
            </Grid>
          </Grid>




          <br />

          {/* <TitleStyle
            title={`ABOUT THE DEPARTMENT`}
          />
          <p align="justify" style={{ fontSize: '1.1em' }}>
            The department of Electronics & Telecommunication Engineering was established in the year 1983. Presently, it offers Bachelor of Engineering (B.E.) course in Electronics & Telecommunication Engineering with an annual intake capacity of 60. The syllabi of the B.E. course cover all major thrust areas in electronics and communication technology and also in the allied fields. The department has been always attracting quality students through the Common Entrance Examination conducted by Government of Assam. It has been always an endeavour for the teachers of this department to impart quality knowledge to the students such that as an engineer they can contribute accountably to the technological world as well as for the betterment of the society.
          </p>

          <br /> */}

          {/* <TitleStyle
            title={`Program Educational Objectives (PEO)`}
          />
          <ol className="list-unstyled">
            <li>
              1. Graduates will attain sound theoretical and practical knowledge for pursuing a 		  career in industry and research-and-development organizations or carrying out 		  higher studies.
                </li>
            <li>
              2. Graduates will possess skills and creativity essential for innovation, entrepreneurship 	 and creation of intellectual property.
                </li>
            <li>
              3. Graduates will imbibe human values, team work and social and professional 		  ethics for building a better environment and society


                </li>

          </ol>

          <br />

          <TitleStyle
            title={`Program Specific Outcomes (PSO)`}
          />
          <ol className="list-unstyled">
            <li>
              1. Students will be able to demonstrate software skills with competence to work and deliver in industry or research.
                </li>
            <li>
              2. Students will be competent to design applications and automation by using modern engineering tools with multi-disciplinary concepts.
                </li>


          </ol> */}

          <br />

          {/* <TitleStyle
          title={`GUIDELINES FOR ADMISSION`}
        />

        <p align="justify">
          400+ seats are up for grabs to get admission in the oldest and most prestigious engineering college of this state through a CEE (Common Entrance Examination) conducted by the ASTU (Assam Science and Technological University ) every year.
        </p>

        <a
          rel="noreferrer noopener"
          target="_blank"
          size="md"
          style={{ fontSize: '0.9em' }}
          href="http://aec.ac.in/undergraduate/disciplines"
        >
          Click Here to know more about Admission
        </a> */}


        </MDBCol>

        <MDBCol lg="4" sm="12" md="4" xs="12">
          <UpcomingEvents />
          <br />
          <div>
            <TitleStyle
              title={`NOTICE BOARD`}
            />
            <HomePageNews />
          </div>

          <br />

        </MDBCol>
      </MDBRow>
      <br />


      {/* <MDBRow>
        <MDBCol lg="12" sm="12" md="12" xs="12">
         

        </MDBCol>

      </MDBRow> */}
      <br />
      <div>
        <Tours />
      </div>
      {/* <RichText
        component_id="1about_the_department"
        isLoggedIn={true}
      />
   */}


      <div>

      </div>
    </MDBContainer>

  );
}

export default gridExamplesPage;


const Tours = (props) => {

  const styleDiv = {
    textAlign: 'center',
    border: 'solid thin lightgrey',
    //width: '50px',
    height: '150px',
    display: 'flex',
    flexDirection: 'column',
    //paddingTop: '40px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 10,
    backgroundColor: "#eaecee"

  }
  const styleText = {
    fontWeight: 700,
    fontSize: '14px',
    padding: 0,
    marginTop: 10
  }

  const smallStyleDiv = {
    textAlign: 'center',
    border: 'solid thin lightgrey',
    width: '50px',
    height: '50px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '30px',
    alignItems: 'center',
    justifyContents: 'center',
    cursor: 'pointer',
  }

  return (


    <div>


      <Grid container spacing={2}>

        <Grid item xs={4} sm={4} lg={2} md={2}>
          <div style={styleDiv} onClick={() => window.location.href = "http://aec.ac.in"}>
            <img
              alt=""
              src="https://www.aec.ac.in/static/media/logo.675b2dd6.png"
              style={{ width: '30%' }}
            />

            <h6 style={styleText}>
              Assam Engineering College
            </h6>
          </div>
        </Grid>
        <Grid item xs={4} sm={4} lg={2} md={2}>
          <div style={styleDiv} onClick={() => window.location.href = "http://dte.assam.gov.in"}>
            <img
              alt=""
              src="/images/gov-of-assam.jpeg"
              style={{ width: '30%' }}
            />

            <h6 style={styleText}>
              Directorate of Technical Education, Assam
            </h6>
          </div>
        </Grid>
        <Grid item xs={4} sm={4} lg={2} md={2}>
          <div style={styleDiv} onClick={() => window.location.href = "https://www.astu.ac.in/"}>
            <img
              alt=""
              src="https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Assam_Science_and_Technology_University_logo.png/220px-Assam_Science_and_Technology_University_logo.png"
              style={{ width: '30%' }}
            />

            <h6 style={styleText}>
              Assam Science & Technology University
            </h6>
          </div>
        </Grid>

        <Grid item xs={4} sm={4} lg={2} md={2}>
          <div style={styleDiv} onClick={() => window.location.href = "https://www.gauhati.ac.in/"}>
            <img
              alt=""
              src="https://upload.wikimedia.org/wikipedia/as/4/42/Gauhati_university_logo.jpg"
              style={{ width: '30%' }}
            />

            <h6 style={styleText}>
              Gauhati University
            </h6>
          </div>
        </Grid>
        <Grid item xs={4} sm={4} lg={2} md={2}>
          <div style={styleDiv} onClick={() => window.location.href = "http://placement.aec.ac.in"}>
            <div>
              <img
                alt=""
                src="https://www.aec.ac.in/static/media/logo.675b2dd6.png"
                style={{ width: '30%' }}
              />
            </div>

            <h4 style={styleText}>
              Training & Placement Cell, AEC
            </h4>
          </div>
        </Grid>





        <Grid item xs={4} sm={4} lg={2} md={2}>
          <div style={styleDiv} onClick={() => window.location.href = "http://aec.ac.in/project/teqip/home"}>
            <div>
          <img
            alt=""
            src={`http://aec.ac.in/static/media/teqip-banner.a25a3fd0.jpg`}
            style={{ width: '120px' }}
          />
        </div>

            <h4 style={styleText}>
              TEQIP-III
            </h4>
          </div>
        </Grid>



        {/* <Grid item xs={4} sm={4} lg={2} md={2}>
          <div style={styleDiv}
          //onClick={() => window.location.href = "/newsletter"}
          >
            <div>
              <img
                alt=""
                src="https://www.aec.ac.in/static/media/logo.675b2dd6.png"
                style={{ width: '30%' }}
              />
            </div>


            <h4 style={styleText}>

              Multi Skilling & Sustainablility Centre


            </h4>
          </div>
        </Grid> */}
        {/* <Grid item xs={4} sm={4} lg={2} md={2}>
          <div style={styleDiv} onClick={() => window.location.href = "/gallery"}>
            <div>
          <img
            alt=""
            src={`/images/stack-of-photos.png`}
            style={{ width: '35%' }}
          />
        </div>

            <h4 style={styleText}>
              GALLERY
            </h4>
          </div>
        </Grid> */}
        <Grid item xs={4} sm={4} lg={2} md={2}>
          <div style={styleDiv} onClick={() => window.location.href = "https://roboclub.aec.ac.in/"}>
            <img
              alt=""
              src="/images/robotics-logo.webp"
              style={{ width: '30%' }}
            />

            <h6 style={styleText}>
              Robotics Club
            </h6>
          </div>
        </Grid>
        <Grid item xs={4} sm={4} lg={2} md={2}>
          <div style={styleDiv} onClick={() => window.location.href = "http://coding.aec.ac.in/"}>
            <img
              alt=""
              src="/images/coding-club-logo.svg"
              style={{ width: '30%' }}
            />

            <h6 style={styleText}>
              Coding Club
            </h6>
          </div>
        </Grid>
        
      </Grid>
      {/* <div style={
      {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 1fr',
        gridRowGap: '10px',
        gridColumnGap: '10px'
      }
    }>


      

     

      
      <div style={{
        textAlign: 'center',

        width: '200px',
        height: '200px',
      }}>

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gridRowGap: '0px',
            gridColumnGap: '0px'
          }}
        >

          <div style={smallStyleDiv} onClick={() => window.location.href = "http://aec.ac.in"}>
            <img
              alt=""
              src="https://upload.wikimedia.org/wikipedia/en/thumb/d/d8/Assam_Engineering_College_Logo.jpg/250px-Assam_Engineering_College_Logo.jpg"
              style={{ width: '30%' }}
            />

            <h6 style={styleText}>
              AEC
        </h6>
          </div>
          <div style={smallStyleDiv} onClick={() => window.location.href = "https://www.gauhati.ac.in/"}>
            <img
              alt=""
              src="https://upload.wikimedia.org/wikipedia/as/4/42/Gauhati_university_logo.jpg"
              style={{ width: '30%' }}
            />

            <h6 style={styleText}>
              GU
        </h6>
          </div>
          <div style={smallStyleDiv} onClick={() => window.location.href = "https://www.astu.ac.in/"}>
            <img
              alt=""
              src="https://upload.wikimedia.org/wikipedia/en/thumb/c/c3/Assam_Science_and_Technology_University_logo.png/220px-Assam_Science_and_Technology_University_logo.png"
              style={{ width: '30%' }}
            />

            <h6 style={styleText}>
              ASTU
        </h6>
          </div>
          <div style={smallStyleDiv} onClick={() => window.location.href = "http://dte.assam.gov.in"}>
            <img
              alt=""
              src="http://uploads.edubilla.com/university/logo/1f/9b/directorate-of-technical-education-assam877.jpg"
              style={{ width: '30%' }}
            />

            <h6 style={styleText}>
              DTE
        </h6>
          </div>

        </div>

      </div>

      </div> */}



    </div >
  )
}


