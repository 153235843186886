import React, { Component } from 'react';
import { Container } from 'mdbreact';
import { Typography, Grid } from '@material-ui/core';
import Example from '../component/RecentNews';
import { GetData } from '../api/service';
import ComingSoon from './ComingSoon';
import { makeStyles, withStyles } from '@material-ui/styles';

const styles = theme => ({
    div: {
        textAlign: 'center'
    }
})

class NewsLetter extends Component {
    state = {
        show: null
    }



    componentDidMount() {
        GetData(`/isittime`)
            .then((resp) => {
                this.setState({
                    show: resp
                })
            })
    }
    render() {
        let { classes } = this.props;
        return (
            <div>
                <Example bread={`Home / Newsletter`} />
                {this.state.show ? <Container style={{ padding: '20px' }}>


                    <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={classes.div}>
                            <Typography
                                variant="h5"
                                gutterBottom
                            >
                                ETE Newsletter (SANCHAR) 2024 (Issue 5)
                            </Typography>
                            <iframe src="https://drive.google.com/file/d/17JiHxaP26NsD6GOLImnuS1KtRL-i5_aW/preview" width="100%" height="480" allow="autoplay"></iframe>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.div}>
                            <Typography
                                variant="h5"
                                gutterBottom
                            >
                                ETE Newsletter (SANCHAR) 2023 (Issue 4)
                            </Typography>
                            <iframe src="https://drive.google.com/file/d/1pxOSF2Jl_E1czUV6_VCsbN6MblU2p6fD/preview" width="100%" height="480" allow="autoplay"></iframe>
                        </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={classes.div}>
                            <Typography
                                variant="h5"
                                gutterBottom
                            >
                                ETE Newsletter (SANCHAR) 2022 (Issue 3)
                            </Typography>
                            <iframe src="https://drive.google.com/file/d/1Bo3NAiX2tskMLCTXErop-s1VpbawNIyz/preview" width="100%" height="480" allow="autoplay"></iframe>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.div}>
                            <Typography
                                variant="h5"
                                gutterBottom
                            >
                                ETE Newsletter (SANCHAR) 2021 (Issue 2)
                            </Typography>
                            <iframe src="https://drive.google.com/file/d/1vaZJM8bNslzKXKO0Mq_An04KhkHY3eP5/preview" width="100%" height="480" allow="autoplay"></iframe>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.div}>
                            <Typography
                                variant="h5"
                                gutterBottom
                            >
                                ETE Newsletter (SANCHAR) 2020 (Issue 1)
                            </Typography>
                            <iframe src="https://drive.google.com/file/d/11Wgt6RcXNFmlMJrRbeEkxznBKmdQ2GNj/preview" width="100%" height="480"></iframe>
                        </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={classes.div}>
                            <Typography
                                variant="h5"
                                gutterBottom
                            >
                                ETE & CSE Magazine (Tele Bytes)
                            </Typography>
                            <iframe src="https://drive.google.com/file/d/1u_PK4wYfW-lb-MzGK3nAU9X-HPl0D5Z-/preview" width="100%" height="480"></iframe>
                        </Grid>

                       

                        {/* <Grid item xs={12} sm={12} md={6} lg={6} className={classes.div}>
                            <Typography
                                variant="h5"
                                gutterBottom
                            >
                                CSE Newsletter (AECbyte)
                            </Typography>
                            <iframe src="https://drive.google.com/file/d/1MnJswSVvrA1UXNwYfaY4l7Uc6WC1Ch5v/preview" width="100%" height="480"></iframe>
                        </Grid> */}
                    </Grid>


                </Container> : null}

                {this.state.show == false ? <Container style={{ padding: '40px', textAlign: 'center' }}>
                    <Typography
                        variant="subtitle2"
                    >
                        Newsletters will be available after 12 noon, 25th January, 2020
                    </Typography>
                </Container> : null}

                {this.state.show == null ? <Container style={{ padding: '20px',textAlign: 'center' }}>
                    Loading . . .
                </Container> : null}
            </div>
        );
    }
}

export default withStyles(styles)(NewsLetter);