import React, { Component } from 'react'

import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdbreact';
import { PostData } from '../../api/service';
import './css/noti.css'
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import Snack from '../../containers/Snack';

const styles = theme => ({
    divGrid: {
        cursor: 'pointer',
        '&:hover': {
            transform: `scale(1.05)`,
            backgroundColor: '#f9f9f9'
        }
    },
    notice: {
        padding: '20px',
        border: 'solid thin #f2f2f2',
        borderLeft: 'solid 3px #BE592D',
        boxShadow: '2px 5px 10px rgba(0,0,0,0.3)'
    }
})

class FullNews extends Component {
    state = {
        news: [],
        isLoaded: false,
        isDownloading: false
    }

    componentDidMount() {
        // perform the api call

        let d = {
            type: 'notice',
            event_type: ''
        }

        PostData(`/getnoticesbytype`, d)
            .then((resp) => {
                //console.log(resp)
                this.setState({
                    news: resp,
                    isLoaded: true
                })

            })


    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }


    showFile = (blob, index) => {



        var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
        var blobUrl = URL.createObjectURL(blob1);


        var link = document.createElement('a');
        link.href = blobUrl;
        //link.download = `${blob.type}_${Math.random() * 1000}.${blob.ext}`;
        link.click();

        this.setState({
            isDownloading: false
        })
        


    }

    getFileName(url){
        const urlParts = url.split('/');
        return urlParts[urlParts.length - 1];
    }

    handleClick(data, index) {

        this.setState({
            isDownloading: true
        })

        PostData(`/getfiledatafordownload`, data)
            .then((resp) => {

                //console.log(resp)
                this.showFile(resp, index)
            })

    }
    render() {
        let {classes} = this.props;
        return (
            <div>
                <h3 style={{ paddingTop: '3vh', paddingLeft: '22vh', color: 'blue' }}>
                    Notifications
                </h3>

                



                {this.state.isLoaded ? (<MDBContainer>

                    {this.state.isDownloading ? (<Snack open={true} message="Downloading . . . ." />) : null}

                    <MDBRow>
                        {this.state.news.map((el, index) => {

                            let i = [];
                            if (el.upload_info !== null) {
                                if (Array.isArray(el.upload_info) && el.upload_info.length > 0) {
                                    i = el.upload_info.map((el1, index1) =>
                                        <li key={index1} item xs={4} sm={4} lg={3} md={3} 
                                        
                                            
                                            >
                                            <a
                                                //onClick={this.handleClick.bind(this, el1, index)}
                                                target="__blank"
                                                href={el1}
                                            >
                                                <i className="fa fa-file-pdf" style={{color: 'red'}}></i> 
                                                &nbsp;
                                                <Typography
                                                    variant="caption"
                                                >
                                                    {this.getFileName(el1)}
                                                </Typography>
                                        </a>
                                        </li>
                                    )
                                }
                            }

                            return (
                                <MDBCol
                                    size="12"
                                    key={index}
                                    className="m-2"
                                >
                                    <div className={classes.notice}>
                                    <span style={{color: "#808b96", fontSize: 12}}><i>Posted at <strong>{el.date}</strong></i></span>

                                        <h5 style={{fontWeight: 700}}>{el.title}</h5>

                                        <p dangerouslySetInnerHTML={{ __html: el.description }} />

                                        <hr />

                                        <ul style={{listStyleType: "none"}} >
                                            {i}
                                        </ul>



                                    </div>
                                </MDBCol>
                            )
                        }

                        )}
                    </MDBRow>

                </MDBContainer>) : null}
            </div>
        )
    }
}

export default withStyles(styles)(FullNews)