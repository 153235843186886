import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'
import ComingSoon from './ComingSoon'
import { makeStyles, withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

const styles = (theme) => ({
    div: {
        textAlign: 'center',
    },
})

class SponsoredPublications extends Component {
    render() {
        let { classes } = this.props
        return (
            <div>
                <Example bread={`Home / Sponsored Research`} />

                <MDBContainer style={{ paddingTop: '30px' }}>
                    <TitleStyle
                        title={`Sponsored Research`}
                    />
                    <br />




                    <table className="table table-sm table-bordered">
  <tbody>
    <tr>
      
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          <strong>Title of the Project</strong>
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          <strong>Principal Investigator (PI) and CO- PI</strong>
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          <strong>Funding Agency and Year of Sanction</strong>
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          <strong>Amount</strong>
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          <strong>Duration</strong>
        </p>
      </td>
    </tr>
    <tr>
      
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          A collaborative research project on “Detection of Assam tea gardens
          using nanomaterial-based Sensor”
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent",
            marginBottom: "0.28cm"
          }}
        >
          Dr. Rashi Borgohain (PI)
        </p>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          <br />
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent",
            marginBottom: "0cm"
          }}
        >
          ASTE Council, Govt of Assam, 2024
        </p>
        <p
          style={{
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent",
            marginBottom: "0cm"
          }}
        >
          Letter no: ASTEC/S&amp; T/192/2023-24/2408
        </p>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          Dated 04/10/2024
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          4.20 Lacs
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          3 years
        </p>
      </td>
    </tr>
    <tr>
      
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          A collaborative research project on "Automated Animal Detection and
          Warning System"
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent",
            marginBottom: "0.28cm"
          }}
        >
          Mr. Nishant Bharti (PI)
        </p>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          Dr. Navajit Saikia (CO-PI)
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          Assam Science and Technology University under TEQIP-III (2019)
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          3.0 Lacs
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          1 year
        </p>
      </td>
    </tr>
    <tr>
      
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          A collaborative research project on “Use of Reversible Logic in
          Hardware Security”
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent",
            marginBottom: "0.28cm"
          }}
        >
          Mr. Gunajit Kalita (PI)
        </p>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          Dr. Navajit Saikia (CO-PI)
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          Assam Science and Technology University under TEQIP-III (2019)
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          2.7 Lacs
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          1 year
        </p>
      </td>
    </tr>
    <tr>
      
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          A collaborative research project on “Fabrication of sensors to detect
          hazardous air pollutants for smart cities”
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent",
            marginBottom: "0.28cm"
          }}
        >
          Dr. Rashi Borgohain (PI)
        </p>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          Prof. Sunandan Baruah (CO-PI)
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          Assam Science and Technology University under TEQIP-III (2019)
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          3.0Lacs
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          1 year
        </p>
      </td>
    </tr>
    <tr>
      
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          A collaborative research project on&nbsp;“Fabrication of biosensors to
          detect different types of bacteria in water”
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent",
            marginBottom: "0.28cm"
          }}
        >
          Dr. Rashi Borgohain (PI)
        </p>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          Prof. Sunandan Baruah (CO-PI)
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          Assam Science and Technology University under TEQIP-III (2019)
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          3.0Lacs
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          1 year
        </p>
      </td>
    </tr>
    <tr>
      
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          A collaborative project on "Centre of Excellence at AEC in Capacity
          Building, Research &amp; Development, Skill Development in ICT Sector
          at North East Region.
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent",
            marginBottom: "0cm"
          }}
        >
          Chief Investigator:
        </p>
        <p
          style={{
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent",
            marginBottom: "0cm"
          }}
        >
          Aditya Kumar Sinha (C-DAC)
        </p>
        <p
          style={{
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent",
            marginBottom: "0cm"
          }}
        >
          Co-Investigators:
        </p>
        <p
          style={{
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent",
            marginBottom: "0cm"
          }}
        >
          Dr. Navajit Saikia
        </p>
        <p
          style={{
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent",
            marginBottom: "0cm"
          }}
        >
          Vineeta Tiwari (CDAC)
        </p>
        <p
          style={{
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent",
            marginBottom: "0cm"
          }}
        >
          Co-ordinators:
        </p>
        <p
          style={{
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent",
            marginBottom: "0cm"
          }}
        >
          Rajashree Konwar
        </p>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          Parimal Wagh (CDAC)
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          C-DAC (Centre for Development of Advanced Computing) (2019)
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          81.0 Lacs
        </p>
      </td>
      <td bgcolor="#ffffff" style={{ background: "#ffffff" }}>
        <p
          style={{
            marginBottom: "0.25cm",
            lineHeight: "115%",
            textAlign: "center",
            background: "transparent"
          }}
        >
          6 m
        </p>
      </td>
    </tr>
  </tbody>
</table>




                </MDBContainer>
            </div>
        )
    }
}

export default withStyles(styles)(SponsoredPublications)
