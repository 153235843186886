import React, { Component } from "react";
import Example from "../component/RecentNews";
import { MDBContainer, MDBBtn, MDBIcon } from "mdbreact";
import { MDBTable, MDBTableBody, MDBTableHead } from "mdbreact";
import TitleStyle, { SecondaryStyle } from "../component/TitleStyle";
import { GetData, PostData } from "../api/service";
import { Button, Tooltip } from "@material-ui/core";

export default class ProjectContainer extends Component {
  state = {
    files: [],
    isLoaded: false,
    downloadIndex: "",
  };
  componentDidMount() {
    // fetch files with code AC

    PostData(`/getfilestoresbytype`, { type: "PR" }).then((resp) => {
      //console.log(resp)
      this.setState({
        files: resp,
        isLoaded: true,
      });
    });
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  showFile = (blob, index) => {
    var blob1 = this.b64toBlob(blob.data, blob.mime, 512);
    var blobUrl = URL.createObjectURL(blob1);

    var link = document.createElement("a");
    link.href = blobUrl;
    //link.download = `${blob.type}_${Math.random() * 1000}.${blob.ext}`;
    link.click();
    this.setState({
      downloadIndex: "",
    });
  };

  triggerDownload = (el, index) => {
    this.setState({
      downloadIndex: index,
    });
    PostData(`/getfiledatafordownloadnew`, el).then((resp) => {
      this.showFile(resp, index);
    });
  };
  render() {
    return (
      <div>
        <Example bread={`Home / Academics / Projects`} />
        <MDBContainer style={{ padding: "20px" }}>
          <div>
            <TitleStyle title={`List of Projects by Our Students`} />
            <br />
          </div>
          <div>
            {this.state.isLoaded ? (
              <div>
                <MDBTable responsive>
                  <MDBTableHead>
                    <tr>
                      <th>#</th>
                      <th>Title</th>
                      <th>File</th>
                    </tr>
                  </MDBTableHead>
                  <MDBTableBody>
                    {this.state.files.map((el, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{el.name}</td>
                        <td>
                            {el.link_text != "" && el.link_text != null && <a style={{color: "blue", fontWeight: 700}} target="_blank" href={el.link_text}>View Link</a>}

                          {el.file.map((el1, index1) => (
                            <Tooltip title={el1.path}>
                              <Button
                                key={index1}
                                variant="text"
                                color="primary"
                                target="_blank"
                                href={el1.url}
                                style={{ color: "blue" }}
                              >
                                {this.state.downlaodIndex === index1 ? (
                                  <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                  <MDBIcon icon="file-pdf" />
                                )}{" "}
                                &nbsp; {el1.path.substring(0, 40)}..
                              </Button>
                            </Tooltip>
                          ))}
                        </td>
                      </tr>
                    ))}
                  </MDBTableBody>
                </MDBTable>
              </div>
            ) : null}
          </div>
        </MDBContainer>
      </div>
    );
  }
}

const TableStyle = (props) => {
  return (
    <div>
      <MDBTable responsive>
        <MDBTableHead>
          <tr>
            <th>#</th>
            <th>Session / Year</th>
            <th>Title</th>
            <th>File</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody>
          {props.data.map((el, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{el.session}</td>
              <td>{el.title}</td>
              <td>{el.file}</td>
            </tr>
          ))}
        </MDBTableBody>
      </MDBTable>

      <div align="right">
        <Button size="sm" color="primary">
          Click to get More
        </Button>
      </div>
    </div>
  );
};
