import React, { Component } from 'react'
import { Container, MDBContainer } from 'mdbreact'
import { Typography, Grid } from '@material-ui/core'
import Example from '../component/RecentNews'
import { GetData } from '../api/service'
import ComingSoon from './ComingSoon'
import { makeStyles, withStyles } from '@material-ui/styles'
import TitleStyle from '../component/TitleStyle'

const styles = (theme) => ({
  div: {
    textAlign: 'center',
  },
})

class FacultyPublications extends Component {
  render() {
    let { classes } = this.props
    return (
      <div>
        <Example bread={`Home / Publications`} />

        <MDBContainer style={{ paddingTop: '30px' }}>
         

        

        <>
  <p
    style={{
      marginBottom: "0cm",
      textAlign: "left",
      background: "transparent",
      fontFamily: "Calibri, serif",
      fontSize: 16,
      marginLeft: "0cm",
      lineHeight: "150%"
    }}
  >
    <strong>SCI/ SCIE/ ESCI/ Reference Journals</strong>
  </p>
  <ol>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Swapna Bharali, Bijit Choudhuri, Brinda Bhowmick. “Investigation of
          hetero gate oxide hetero stacked triple metal vertical tunnel FET with
          variable interface trap charges and temperature ”Microelectronics
          Journal, Volume 143, 2024. DOI:{" "}
          <a href="https://doi.org/10.1016/j.mejo.2023.106054">
            https://doi.org/10.1016/j.mejo.2023.106054
          </a>
          .
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Lachit Dutta, Swapna Bharali, Pranjal Barman, &nbsp;
          <a href="https://link.springer.com/article/10.1007/s40003-024-00702-0#auth-Amarprit-Singh-Aff4">
            Amarprit Singh
          </a>{" "}
          , “An IoT-Enabled Smart pH Monitoring and Dispensing System for
          Precision Agriculture Application.” Agric Res 13, 309–318 (2024). Doi:
          https://doi.org/10.1007/s40003-024-00702-0
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Bhargav Jyoti Das, Dhritiman Choudhury, Jyotirmoy Dutta, Navajit
          Saikia, Siddhanta Borah and Tanmoy Goswami. "IoT-Based Weather
          Monitoring System." ADBU Journal of Engineering Technology, vol. 12,
          no. 2, 2023
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Amlan Jyoti Das, Simantika Choudhury, Navajit Saikia, Subhash
          Rajbongshi, “Pedestrian detection in thermal and color images using a
          new combination of saliency network and Faster R-CNN” May 2023,
          International Journal of Computing and Digital Systems, May 2023 DOI:
          10.12785/ijcds/1301101.&nbsp;
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Pranjal Barman, Lachit Dutta, Sushanta Bordoloi, Anamika Kalita,
          Pronamika Buragohain, Swapna Bharali, Brian Azzopardi. “Renewable
          energy integration with electric vehicle technology: A review of the
          existing smart charging approaches.” Renewable and Sustainable Energy
          Reviews, Volume 183, 2023. DOI:
          https://doi.org/10.1016/j.rser.2023.113518.
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Subhradip Mukherjee, R. Kumar and Siddhanta Borah, "Predictive
          Mechanism of a Modified Bug Controller for Mobile Robot Path
          Navigation", International Journal of Vehicle Information and
          Communication Systems, vol. 7, no. 2, pp. 101-120, April 28, 2022.
          Doi:{" "}
          <a href="https://doi.org/10.1504/IJVICS.2022.122546">
            <span style={{ background: "#ffffff" }}>
              https://doi.org/10.1504/IJVICS.2022.122546
            </span>
          </a>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Subhradip Mukherjee , R. Kumar and Siddhanta Borah, “ Obstacle
          Avoiding Intelligent Algorithm for Quad Wheel Robot Path Navigation,”
          International Journal of Intelligent Unmanned Systems (IJIUS), vol. 9,
          no. 1, pp. 29-41. doi:{" "}
          <a
            href="https://doi.org/10.1108/IJIUS-12-2019-0074"
            target="DOI: https://doi.org/10.1108/IJIUS-12-2019-0074"
          >
            <u>https://doi.org/10.1108/IJIUS-12-2019-0074</u>
          </a>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Mukherjee, S., Kumar, R., &amp; Borah, S., “An intelligent fast
          controller for autonomous wheeled robot path navigation in challenging
          environments”,
          <em>
            Industrial Robot: the international journal of robotics research and
            application
          </em>
          , 2022.{" "}
          <a href="https://www.emerald.com/insight/content/doi/10.1108/IR-01-2022-0026/full/html">
            DOI:https://doi.org/10.1108/IR-01-2022-0026
          </a>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Siddhanta Borah and R. Kumar, “Design and characterisation of a
          low-cost capacitive soil moisture sensor system for IoT based
          agriculture applications,” Journal of Information Technology
          Management, vol. 15, no. 1, pp. 164-186, 2023.
          <a href="doi%3A%20https%3A//doi.org/%2010.22059/jitm.2023.91574.">
            DOI: https://doi.org/ 10.22059/jitm.2023.91574.
          </a>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Siddhanta Borah and R. Kumar, “Development of embedded moisture
          sensing device for distributive network to control irrigation using
          IoT,” Journal of Information Technology Management, vol. 15, no. 1,
          pp. 95-111, 2023.{" "}
          <a href="doi%3A%20https%3A//doi.org/%2010.22059/jitm.2023.91570.">
            DOI: https://doi.org/ 10.22059/jitm.2023.91570.
          </a>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Siddhanta Borah, R Kumar, Khalid Mohd. Ibrahimi and Rituraj
          Bhattacharjee, "An Experimental Analysis of Image Extract algorithm
          (IEa) in UWB Communication medium to Make a Smart Pesticide Sprayer
          for Cassava Agriculture Crops”, International Journal of
          Ultra-Wideband Communications and Systems, vol. 4, no. 3, pp. 161-169,
          doi: <a href="http://www.http/">10.1504/IJUWBCS.2021.10042921.</a>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span style={{ background: "#ffffff" }}>
          &nbsp;
          <span size={3} style={{ fontSize: 16 }}>
            Siddhanta Borah, R. Kumar, Subhradip Mukherjee, Fenil. C.Panwala and
            A. Prasanna Lakshmi , “An Experimental Analysis of Quad Wheel
            Autonomous Robot Location and Path Planning Using Borahsid Algorithm
            (BsA) with GPS and Zigbee”, International Journal of Vehicle
            Information and Communication Systems (IJVICS), vol. 7, no. 3, pp.
            290-305.{" "}
          </span>
        </span>
        <span size={3} style={{ fontSize: 16 }}>
          <a href="https://www.inderscienceonline.com/doi/pdf/10.1504/IJVICS.2022.127405">
            <span style={{ background: "#ffffff" }}>
              DOI: 10.1504/IJVICS.2022.127405
            </span>
          </a>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Siddhanta Borah, R. Kumar and Subhradip Mukherjee, “ Low-cost IoT
          framework to Control and Monitoring Irrigation,” International Journal
          of Intelligent Unmanned Systems (IJIUS), vol. 9, no. 1, pp. 63-79,
          doi:{" "}
          <u>
            <a
              href="https://doi.org/10.1108/IJIUS-12-2019-0075"
              target="DOI: https://doi.org/10.1108/IJIUS-12-2019-0075"
            >
              https://doi.org/10.1108/IJIUS-12-2019-0075
            </a>
            .
          </u>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Siddhanta Borah, R. Kumar, “Designing an Optimized RLC Network for
          Efficient Soil Moisture Data Logger System Using IoT,” Wireless
          Personal Communication, 2023. DOI:{" "}
          <a href="https://doi.org/10.1007/s11277-023-10782-w">
            https://doi.org/10.1007/s11277-023-10782-w
          </a>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Savio Jay Sengupta, Bijoy Goswami Pritam Das, Subir Kumar Sarkar “A
          Noise Immune Double Suspended Gate MOSFET for UltraLow-Power
          Applications” July 2022, Silicon 14(7), DOI:
          10.1007/s12633-021-01283-1
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Dipanjan Sen, Arpan De, Bijoy Goswami, Sharmistha Shee, Subir Kumar
        Sarkar “Noise Immune Dielectric Modulated Dual Trench Transparent Gate
        Engineered MOSFET as a Label Free Biosensor: Proposal and Investigation”
        Journal of Computational Electronics 20, 2594–2603 (Sept, 2021).{" "}
        <a href="https://doi.org/10.1007/s10825-021-01780-x">
          https://doi.org/10.1007/s10825-021-01780-x
        </a>
        .
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          marginRight: "0.17cm"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Partha Saha and Mrinal Sen, “Ultrahigh Q-Factor and Ultrasensitive
          Refractive Index Sensor Based on a Multiple-Slot Photonic Crystal
          Cavity,” IEEE Transactions on Instrumentation and Measurement, vol.
          70, pp. 1-9, 2021.&nbsp;
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Lakhyadeep Konwar, Anjan Talukdar, Kandarpa Sarma, Navajit Saikia,
        Subhash Rajbangshi, “Segmentation and Selective Feature Extraction for
        Human Detection to the Direction of Action Recognition”, International
        Journal of Circuits, Systems and Signal Processing, 15:1371-1386,
        September, 2021. DOI: 10.46300/9106.2021.15.147.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Savio Jay Sengupta, Bijoy Goswami, Pritam Das, Subir Kumar Sarkar , “A
        Noise Immune Double Suspended Gate MOSFET for Ultra Low-Power
        Applications” Silicon (Aug, 2021).{" "}
        <a href="https://doi.org/10.1007/s12633-021-01283-1">
          https://doi.org/10.1007/s12633-021-01283-1
        </a>
        .
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Dr. Lachit Dutta, Swapna Bharali, “TinyML Meets IoT: A Comprehensive
        Survey.” Internet of Things, Volume 16, 2021, Doi:
        https://doi.org/10.1016/j.iot.2021.100461.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Swarnav Mukhopadhyay, Dipanjan Sen, Bijoy Goswami, Subir Kumar Sarkar,
        “Performance Evaluation of Dielectrically Modulated Extended Gate Single
        cavity InGaAs/Si HTFET based Label-free Biosensor Considering Non-ideal
        Issues” IEEE Sensors Journal ( Volume: 21, Issue: 4, Feb.15, 15 2021)
        Page(s): 4739 – 4746 DOI: 10.1109/JSEN.2020.3033576
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        A. Gupta, P. Mishra, M. Senapati &amp; P.P. Sahu, A novel
        electrochemical sensor for detection and removal of naringin from citrus
        juice. Journal of Food Engineering, 306, 110637, 2021. (Journal, IF
        6.203), DOI:{" "}
        <a href="https://doi.org/10.1016/j.jfoodeng.2021.110637">
          https://doi.org/10.1016/j.jfoodeng.2021.110637
        </a>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        M. Senapati &amp; P.P. Sahu. Meat Quality Assessment Using Au Patch
        Electrode Ag-SnO2/SiO2/Si MIS Capacitive Gas Sensor at Room Temperature.
        Food Chemistry. 324, 126893, 2020. (Journal, IF 9.231) DOI:{" "}
        <a href="https://doi.org/10.1016/j.foodchem,2020.126893">
          https://doi.org/10.1016/j.foodchem,2020.126893
        </a>
        .
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          marginRight: "0.17cm"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Partha Saha and Mrinal Sen, “NOEMS Based Slotted Photonic Crystal
          Cavity for the Sensing of Force,” IEEE Transactions on Nanotechnology,
          vol. 20, pp. 20- 27, 2020.&nbsp;
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          marginTop: "0.21cm"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          M. Senapati &amp; P.P. Sahu. Onsite Fish Quality monitoring using
          Ultra-sensitive patch electrode Capacitive Sensor at Room Temperature.
          Biosensor and Bioelectronics, 168, 112570, 2020. (Journal, IF 12.54),
          DOI:{" "}
          <a
            href="https://doi.org/10.1016/j.bios.2020.112570"
            target="Persistent link using digital object identifier"
          >
            https://doi.org/10.1016/j.bios.2020.112570
          </a>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Simantika Choudhury, Nishant Bharti, Navajit Saikia and Subhash Chandra
        Rajbongshi, “Detection of One-horned Rhino from Green Environment
        Background using Deep Learning”, Journal of Green Engineering, vol. 10,
        pages 4657-4678, September, 2020.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Gunajit Kalita, Navajit Saikia and Amit Sravan Bora, “Design of
        Reversible Decoder with minimum Garbage Output”, International Journal
        of Advanced Trends in Computer Science and Engineering 9(3):3463-3470,
        June, 2020. DOI: 10.30534/ijatcse/2020/150932020.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Gautam Chakraborty, and Navajit Saikia, “Speech Recognition of Isolated
        Words using a New Speech Database in Sylheti”, International Journal of
        Recent Technology and Engineering (IJRTE), Volume-8 Issue-3, September
        2019, pp. 6259-6268.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Disha Bhattacharjee , Bijoy Goswami ,Dinesh Kumar Dash , Ayan
        Bhattacharya and Subir Kumar Sarkar “Analytical modelling and simulation
        of drain doping engineered splitted drain structured TFET and its
        improved performance in subduing ambipolar effect” IET Circuits, Devices
        &amp; Systems ( Volume: 13 , Issue: 6 , 9 2019 ), Page(s): 888 – 895,
        DOI: 10.1049/iet-cds.2018.5261
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Amlan Jyoti Das and Navajit Saikia, “Design of pedestrian detectors
        using combinations of scale spaces and classifiers”, in Journal of King
        Saud University - Computer and Information Sciences (JKSUCI 2019), July,
        2019, doi:{" "}
        <a href="https://doi.org/10.1016/j.jksuci.2019.06.017">
          https://doi.org/10.1016/j.jksuci.2019.06.017
        </a>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          marginRight: "0.17cm"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Partha Saha and M.Sen “A slotted photonic crystal nanobeam cavity for
          simultaneous attainment of ultra-high Q-factor and sensitivity,” IEEE
          Sensors Journal, vol. 18, no. 9, 2018.
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Rashi Borgohain, Reema Das, Biplob Mondal, VisittapongYordsri,
        ChanchanaThanachayanont, and Sunandan Baruah. "ZnO/ZnS Core-Shell
        Nanostructures for Low-Concentration NO 2 Sensing at Room Temperature."
        IEEE Sensors Journal 18, no. 17 (2018): 7203-7208.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Rashi Borgohain and Sunandan Baruah. "Development and Testing of ZnO
        Nanorods Based Biosensor on Model Gram-Positive and Gram-Negative
        Bacteria."&nbsp;IEEE Sensors Journal&nbsp;17.9 (2017): 2649-2653.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Gunajit Kalita and Navajit Saikia, “Designing reversible arithmetic,
        logic circuit to implement micro-operation in quantum computation”, in
        Journal of Physics: Conference Series, vol. 759, no. 1, 2016, DOI:
        10.1088/1742-6596/759/1/012097.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          marginRight: "0.17cm"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Partha Saha, R. H. Laskar, A. Laskar, “A pre-processing method for
          improvement of vowel onset point detection under noisy conditions”,
          Speech Communication (Elsevier) vol. 80, pp. 71-83, 2016.
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          marginRight: "0.17cm"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Partha Saha, R.H.Laskar, U.Baruah, S.Misra, S.P.Choudhury, T.K.Das,
          “Robust analysis for improvement of vowel onset point detection under
          noisy conditions”, International Journal of Speech Technology
          (Springer) vol. 19, pp. 433-448, 2016.
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Rashi Borgohain, Prabin Kumar Boruah, and Sunandan Baruah. "Heavy-metal
        ion sensor using chitosan capped ZnS quantum dots."&nbsp;Sensors and
        Actuators B: Chemical&nbsp;226 (2016): 534-539.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Rashi Borgohain, et al. "Detection of Zn2+ ion with UV light activated
        ZnO nanorods."&nbsp;ADBU Journal of Engineering Technology&nbsp;5.1
        (2016).
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Rashi Borgohain and Sunandan Baruah. "Design and analysis of UV detector
        using ZnO nanorods on interdigitated electrodes."&nbsp;ADBU Journal of
        Engineering Technology&nbsp;4 (2016).
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          marginRight: "0.17cm"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          S. Mishra, R. H. Laskar, U. Baruah, T. K. Das, P. Saha, S. P.
          Choudhury, “Analysis and extraction of LP-residual its application in
          speaker verification system under uncontrolled noisy environment”,
          Multimedia Tools and Applications (Springer),
          doi:10.1007/s11042-015-3020-8, pp. 1-28, Nov. 2015.
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          marginRight: "0.17cm"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          S.Misra, T.K.Das, S.P.Choudhury, R.H.Laskar, U.Baruah, P.Saha
          “Choosing optimal value for fuzzy membership in FCM algorithm for
          LP-residual input features,”Procedia Computer Science (Elsevier), vol.
          54, pp. 542-548, 2015.&nbsp;
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          marginRight: "0.17cm"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          D. Sarkar, P. Saha, R. K. Das, D. Roy, “A smart traffic congestion
          control method”, IJECT, vol. 6, no. 3, Sept. 2015.
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Navajit Saikia and Prabin K. Bora, “Perceptual hash function for
        scalable video”, in International Journal of Information Security, vol.
        13, no.1, Feb, 2014, DOI: 0.1007/s10207-013-0211-z.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        D. Hazarika and D.S. Pegu, “Micro-controller based air pressure
        monitoring instrumentation system using optical fiber as sensor”,{" "}
        <em>Optical Fiber Technology 19 (2013)83-87.&nbsp;</em>
        <a href="https://doi.org/10.1016/j.yofte.2012.11.006">
          https://doi.org/10.1016/j.yofte.2012.11.006
        </a>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Ananya Choudhury and Kandarpa Kumar Sarma (2023), “
        <span style={{ background: "#fcfcfc" }}>
          Trajectory-based recognition of in-air handwritten Assamese words
          using a hybrid classifier network”,&nbsp;
        </span>
        <em>International Journal of Document Analysis and Recognition</em>
        <span style={{ background: "#fcfcfc" }}>,&nbsp;</span>
        <u>
          <span style={{ background: "#fcfcfc" }}>DOI:</span>
        </u>
        <a href="https://doi.org/10.1007/s10032-022-00426-3">
          <span style={{ background: "#fcfcfc" }}>
            https://doi.org/10.1007/s10032-022-00426-3
          </span>
        </a>
        (SCI indexed, Impact Factor- 3.87)
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Ananya Choudhury and Kandarpa Kumar Sarma
        <span lang="en-GB">
          &nbsp;(2021), “A CNN-LSTM based ensemble framework for in-air
          handwritten Assamese character recognition”,&nbsp;
        </span>
        <span lang="en-GB">
          <em>Multimedia Tools and Applications</em>
        </span>
        <span lang="en-GB">, 1-36,&nbsp;</span>
        <span lang="en-GB">
          <u>DOI: https://doi.org/10.1007/s11042-020-10470-y</u>
        </span>
        <span lang="en-GB">&nbsp;(SCI indexed, Impact Factor- 2.75)</span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Ananya Choudhury<span lang="en-GB">, A. K. Talukdar,&nbsp;</span>
        <span lang="en-GB">
          K. K. Sarma and M. K. Bhuyan (2021), “An Adaptive Thresholding-Based
          Movement Epenthesis Detection Technique Using Hybrid Feature Set for
          Continuous Fingerspelling Recognition”,&nbsp;
        </span>
        <span lang="en-GB">
          <em>SN Computer Science</em>
        </span>
        <span lang="en-GB">,&nbsp;</span>
        <span lang="en-GB">
          <em>2</em>
        </span>
        <span lang="en-GB">(2), 1-21,&nbsp;</span>
        <span lang="en-GB">
          <u>DOI: https://doi.org/10.1007/s42979-021-00544-5</u>
        </span>
        <span lang="en-GB">&nbsp;(SCOPUS indexed).</span>
      </p>
    </li>
  </ol>
  <p
    style={{
      marginLeft: "0.75cm",
      marginBottom: "0cm",
      lineHeight: "150%",
      textAlign: "justify",
      background: "transparent",
      fontFamily: '"Calibri", serif',
      fontSize: 16
    }}
  >
    <br />
  </p>
  <p
    style={{
      marginLeft: "0cm",
      marginBottom: "0cm",
      lineHeight: "150%",
      textAlign: "justify",
      background: "transparent",
      fontFamily: '"Calibri", serif',
      fontSize: 16
    }}
  >
    <strong>Other Journal</strong>
  </p>
  <ol>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Simantika Choudhury, Amlan Jyoti Das, Navajit Saikia, Chandra
          Rajbongshi “Detection of one-horned rhino using multispectral images”
          September 2023, ADBU Journal of Engineering Technology (AJET),&nbsp;
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          GI Hussain, R Borgohain, “Nanomaterial-based Sensing of
          Low-concentration NO2 Gas: A Review”, ADBU Journal of Electrical and
          Electronics Engineering (AJEEE) 5 (2), 1-6, 2023
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          U Das, NS Baruah, B Das, R Borgohain,“Detection of diseases in fruits
          using Image Processing Techniques”, ADBU Journal of Electrical and
          Electronics Engineering (AJEEE) 5 (2), 7-17, 2023
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          BijoyGoswami,RijuKalita,ProfSubirKumarSarkar,“GateCentricExtendedSourceSOITFET”
          ,Volume12,Issue2,September2023,0120204062,(5PP)ADBU-JournalofEngineeringTechnology,ISSN:2348-7305,
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Ankur Jyoti Sarmah , Gaurab Narayan Dutta, DaksheeLahkar, Neelotpal
          Talukdar, BimrishaLahkar, “Biometric Authentication- Person
          Identification using Iris Recognition”, International Journal of
          Innovative Science and Research Technology. Article Digital No:
          IJISRT22MAY1505, Volume 7, Issue 5, May,2022. DOI :
          <a href="https://doi.org/10.5281/zenodo.6658785">
            https://doi.org/10.5281/zenodo.6658785
          </a>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Ankur Jyoti Sarmah, Bhaskar Borah, Anirban Saikia, Madhusmita Nath,
          “Animal Detection Using Instance Segmentation (ISSN: 0012 2440)”,
          Dickensian Journal. DOI: 10. 12001.DK. J2022.V22. I6.430, Volume 22,
          Issue 6, June, 2022.
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Niranjan Jyoti Borah, G Kumar, “Performance Analysis of Sub-22nm Metal
          Gate Fully Depleted SOI MOSFET with High-K Gate Dielectric”,
          International Journal of Global Technology Initiatives 3 (1)(2014),
          B161-B168
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Ananya Choudhury and Kandarpa Kumar Sarma
          <span lang="en-GB">
            {" "}
            (2021), “A Vision-Based Framework for Spotting and Segmentation of
            Gesture-Based Assamese Characters Written in the Air”,{" "}
          </span>
          <span lang="en-GB">
            <em>Journal of Information Technology Research (JITR)</em>
          </span>
          <span lang="en-GB">,</span>
          <span lang="en-GB">
            <em>&nbsp;14</em>
          </span>
          <span lang="en-GB">(1), 70-91, </span>
          <span lang="en-GB">
            <u>DOI: 10.4018/JITR.2021010105</u>
          </span>
          <span lang="en-GB"> (Web of Science indexed, </span>Impact Factor:0.9
          <span lang="en-GB">)</span>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Ananya Choudhury, A. K. Talukdar and K. K. Sarma
          <span lang="en-GB">
            {" "}
            (2017), “Movement epenthesis detection for continuous sign language
            recognition”,{" "}
          </span>
          <span lang="en-GB">
            <em>&nbsp;Journal of Intelligent Systems</em>
          </span>
          <span lang="en-GB">, </span>
          <span lang="en-GB">
            <em>26</em>
          </span>
          <span lang="en-GB">(3), 471-481, </span>
          <span lang="en-GB">
            <u>DOI:&nbsp;</u>
          </span>
          <a href="https://doi.org/10.1515/jisys-2016-0009" target="_blank">
            <span lang="en-GB">https://doi.org/10.1515/jisys-2016-0009</span>
          </a>
          <span lang="en-GB">&nbsp;(SCOPUS indexed, Impact Factor- 2.1)</span>
        </span>
      </p>
    </li>
  </ol>
  <p
    style={{
      marginLeft: "0cm",
      marginBottom: "0cm",
      lineHeight: "150%",
      textAlign: "justify",
      background: "transparent"
    }}
  >
    <br />
  </p>
  <p
    style={{
      marginLeft: "0cm",
      marginBottom: "0cm",
      lineHeight: "150%",
      textAlign: "justify",
      background: "transparent",
      fontFamily: '"Calibri", serif',
      fontSize: 16
    }}
  >
    <strong>International Conference</strong>
  </p>
  <ol>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0.35cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          <span lang="en-IN">
            Kh. Alisha Singha, Aditya Singha, Swrang Brahma, Karapallav Chutia
            and Dr. Bijoy Goswami, “ Design and Simulation of Broken Gate TFET
            and its RF Applications” 10
          </span>
          <sup>
            <span lang="en-IN">th</span>
          </sup>
          <span lang="en-IN">
            &nbsp;IEEE International Conference on Digital Arts, Media and
            Technology (DAMT) and 8th ECTI Northern Section Conference on
            Electrical, Electronics, Computer and Telecommunications Engineering
            (NCON), Nan, Thailand from 30 January - 3 February 2025.&nbsp;
          </span>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0.35cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          <span lang="en-IN">
            Susankar Borgohain, Khrishtina Dutta, Rashmi Choudhury, Niranjan
            Jyoti Borah, Ruchira Mazumdar, PK Dutta, Mriganka Gogoi, “Design of
            Temperature Resistant Dual Band VCO with Integrated TFC Circuit for
            Studying Aging Affect using Machine Learning Framework” IEEE
            International Nanoelectronics Conference 2025,&nbsp;
          </span>{" "}
          3-6 Jan, 2025, <span lang="en-IN">Taiwan&nbsp;</span>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          <span lang="en-IN">
            Mr. Ayan Bhattacharya, Dr. Bijoy Goswami, and Dr. Nalin Bihari Dev
            Choudhury, “A Source-Drain Engineering Di-Electrically Modulated
            Double Gate TFET Based Label-Free Biosensor” IEEE TENCON, Singapore,
            1
          </span>
          <sup>
            <span lang="en-IN">st</span>
          </sup>
          <span lang="en-IN">&nbsp;Dec-4</span>
          <sup>
            <span lang="en-IN">th</span>
          </sup>
          <span lang="en-IN">&nbsp;Dec, 2024.&nbsp;</span>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          <span lang="en-IN">
            Abhiraj Paul, Amandeep Vasistha, Kalpeswar Paul, SubhadeepKarmakar,
            Siddhanta Borah, Navajit Saikia and NiranjanJyoti Borah,
            “Forecasting Air Quality: A Convergence of Machine Learning andIoT
            for Pollution Detection and Prediction”, 4
          </span>
          <sup>
            <span lang="en-IN">th</span>
          </sup>
          <span lang="en-IN">
            &nbsp;International Conference on Modeling, Simulation
            andOptimization (CoMSO 2024), 16
          </span>
          <sup>
            <span lang="en-IN">th</span>
          </sup>
          <span lang="en-IN">&nbsp;to 18</span>
          <sup>
            <span lang="en-IN">th</span>
          </sup>
          <span lang="en-IN">
            &nbsp;November, 2024 held at National Institute ofTechnology
            Silchar, Assam, India.
          </span>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          <span style={{ background: "#ffffff" }}>
            Anupal Deuri Bharali, Bhupali Sarma, Ritul Paul, Tushar Nath, Mrs.
            Riju Kalita, Dr.Bijoy Goswami “Dual Source SOI TFET for Inverter
            Application” February 2024, International conference On Advances in
            sustainable development, innovation and green technology (ICASDGIT
            2024), Guwahati, India.&nbsp;
          </span>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          <span style={{ background: "#ffffff" }}>
            Gunajit Kalita, Navajit Saikia, Amit Sravan Bora “A Design approach
            for Reversible SR and D flip flop” February 2024, International
            conference On Advances in sustainable development, innovation and
            green technology (ICASDGIT 2024), Guwahati, India
          </span>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          <span style={{ background: "#ffffff" }}>
            B Goswami, R Kalita, SK Sarkar, “Gate Centric Extended Source SOI
            TFET” New Approaches of Basic Sciences towards Engineering and
            Technology – 2023( NABSET 2023)
          </span>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          <span style={{ background: "#ffffff" }}>
            Subhradip Mukherjee, R.Kumar, Siddhanta Borah, “An Experimental
            Study of a Mobile Robot for Emergency Search and Rescue Operations”,
            ISERDM, NIT Tiruchirappalli, India, 9-11 January, 2023.
          </span>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          <span style={{ background: "#ffffff" }}>
            Aparajita Das, Navajit Saikia, Subhash Ch. Rajbongshi, Kandarpa
            Kumar Sarma “
          </span>
          <a href="https://www.researchgate.net/publication/369072965_Human_Activity_Recognition_based_on_Stacked_Autoencoder_with_Complex_Background_Conditions?_sg%5B0%5D=uwKgM8IvANpBwwFgBngAhxkt-QtFtDs3UzhE3MLA5BOErR7kW9q9xl6u6Db7q9IDK6xTc1MQlorf_arDo9WAhD4p_KD0Yap3nmZoTTCp.3r73v7iwv7SQjlF0jm4rP4QFewo4pKvgUX77eftliurdMVlS9iUyQMKAB6SjX5zUoFDjZIC19ZLgn982wgBFyg&_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSIsInByZXZpb3VzUGFnZSI6InByb2ZpbGUiLCJwb3NpdGlvbiI6InBhZ2VDb250ZW50In19">
            Human Activity Recognition based on Stacked Autoencoder with Complex
            Background Conditions
          </a>
          <span style={{ background: "#ffffff" }}>
            ” December 2022, International Conference on Information Technology
            (OCIT)
          </span>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          <span style={{ background: "#ffffff" }}>
            Subhradip Mukherjee, R.Kumar, Siddhanta Borah, Rituraj
            Bhattacharjee, “An Enhanced Experimental Study of GPS Based Path
            Tracking Non-holonomic Robot with SAWOA Algorithm”, ICCSEA, Gunupur,
            India, 13-14 March, 2020,{" "}
            <a href="http://www.http/">
              doi: 10.1109/ICCSEA49143.2020.9132956.
            </a>
          </span>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Subhradip Mukherjee, R. Kumar, Siddhanta Borah, “Computational Study
          of DFMB Algorithm for Unmanned Vehicle in Static 2D Environment”,
          ComPE-2020, Shillong, India, 2-4<sup>th</sup> July, 2020,{" "}
          <a href="https://scholar.google.com/citations?user=Y6zuY1QAAAAJ&hl=en">
            doi: 10.1109/ComPE49325.2020.9200179.
          </a>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Siddhanta Borah, R. Kumar and S. Mukherjee, "Study of RTPPS algorithm
          in UWB communication medium for a surveillance system to protect
          agricultural crops from wild animals," 2020 IEEE International
          Symposium on Smart Electronic Systems (iSES), 2020, pp. 121-126,{" "}
          <a href="https://scholar.google.com/citations?user=Y6zuY1QAAAAJ&hl=en">
            doi: 10.1109/iSES50453.2020.00036.
          </a>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Siddhanta Borah, R. Kumar, Subhradip Mukherjee, “A Low Cost and Power
          Efficient IoT Based Capacitive Sensor System to Measure Soil
          Moisture”, ComPE-2020, Shillong, India, 2-4<sup>th</sup> July, 2020,
          doi: 10.1109/ComPE49325.2020.9200006.
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Rajarshi Das, Roshan Kumar Ojha, DipamTamuli, Swapnil bhattacharjee and
        Niranjan Jyoti Borah, “Hand Gesture-Based Recognition System for
        Human-Computer Interaction”, International Conference on Machine Vision
        &amp; Augmented Intelligence (MAI 2022) held on 04-07, March 2022 hosted
        by NIT Jamshedpur.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Simantika Choudhury, Navajit Saikia, Subhash Chandra Rajbongshi and
        Abhishek Das, “Employing generative adversarial network in low light
        animal detection”, 4th International Conference on Communication and
        Computational Technologies (ICCCT 2022). Date: 26-27 February, 2022,
        Proceedings to be published in Springer Book Series 'Algorithms and
        Intelligent Systems'.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Amlan Jyoti Das, Navajit Saikia, Simantika Choudhury, “Cascade-Based
        Pedestrian Detector Using Edge and Pattern Features”, in: Bora P.K.,
        Nandi S., Laskar S. (eds) Emerging Technologies for Smart Cities.
        Lecture Notes in Electrical Engineering, vol 765. Springer, Singapore,
        2021.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Ursula Das, Neha Spriha Baruah, Bishal Das, Rashi Borgohain, “Fruit
        Disease Detection using Image Processing Techniques” published in the
        proceedings of the AICTE sponsored International Conference on Advances
        in Computer Engineering &amp; Communication Technology (ICACET-2021)
        held during 22nd -23rd October 2021
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Simantika Choudhury, Nishant Bharti, Navajit Saikia and Subhash Chandra
        Rajbongshi, “Automatic detection of Greater One-horned Rhinos by Using
        Deep Learning Models”, in 6th International Online Conference on Recent
        Advancements in Interdisciplinary Research (ICRAIR),AIT, Conference
        Center, Thailand, 26-27 September, 2020.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Bijoy Goswami, Savio Jay Sengupta, Wasim Reja, Pritam Das, Subir Kumar
        Sarkar “Validation of Input/Output characteristics of Symmetrical Double
        Source TFET device” 2021 Devices for Integrated Circuit (DevIC), 19-20
        May 2021, Kalyani, India, Publisher: IEEE, DOI:
        10.1109/DevIC50843.2021.9455764
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Bijoy Goswami, Pritam Das, Abhishek Dey; Savio Jay Sengupta, Subir Kumar
        Sarkar “Trapezoidal Channel Double Gate Tunnel FET Suitable for better
        Scalability, Speed and Low Power Application” 2021 Devices for
        Integrated Circuit (DevIC) , 19-20 May 2021, , Kalyani, India ,
        Publisher: IEEE, DOI: 10.1109/DevIC50843.2021.9455811
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Gautam Chakraborty, Mridusmita Sharma, Navajit Saikia, Kandarpa Kumar
        Sarma and N.E. Mastorakis, “Speech Database in Sylheti and Speech
        Recognition using Convolutional Neural Network”, in 24th International
        Conference on Circuits, Systems, Communication and Computers, Platanias,
        Chania Crete Island, Greece, July 19-22, 2020.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Abhishek Roy, Bijoy Goswami, Ujjwal Dey, Debasish Gayen, Wasim Reja and
        Subir Kumar Sarkar, “Impact of Trapezoidal Channel in Double-Gate Tunnel
        Field Effect Transistor on Ambipolar Conduction for Ultra Low-Power
        Application” The 17th IEEE International Conference on Electrical
        Engineering/Electronics, Computer, Telecommunications and Information
        Technology (ECTI-CON 2020), 24-27 June 2020, Phuket, Thailand,
        Publisher: IEEE ,DOI: 10.1109/ECTI-CON49241.2020.9158233
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Dipanjan Sen, Bijoy Goswami, Anup Dey, Priyanka Saha and Subir Kumar
        Sarkar “Impact of Self-Heating and Nano-Gap Filling Factor on
        AlGaAs/GaAs Junction-Less DG-MOSFET Based Biosensor for Early Stage
        Diagnostics” 2020 IEEE Region 10 Symposium (TENSYMP), 5th -7th June
        2020, Dhaka, Bangladesh , Publisher: IEEE, DOI:
        10.1109/TENSYMP50017.2020.9230864
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Abir Jana, Disha Bhattacharjee, Komal Kumari, Anup Dey, Bijoy Goswami,
        Subir Kumar Sarkar “Designing Memristor Based Timing Circuits and
        Performance Comparison with CMOS Counterparts” in Fourth International
        Conference on Smart Trends for Computing &amp; Communications (SmartCom
        2020),January 24-25, 2020 at Hotel Novotel, Siam Square, Bangkok,
        Thailand, Publisher: Springer, Singapore
        DOI:10.1007/978-981-15-5224-3_26
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Anup Dey , Komal Kumari, Abir Jana, Bijoy Goswami, Poushali Nandi, S. K.
        Sarkar “Room temperature Pt modified WO3/p-Si Film Gas Sensor for
        Detection of Methanol” in Fourth International Conference on Smart
        Trends for Computing &amp; Communications (SmartCom 2020),January 24-25,
        2020 at Hotel Novotel, Siam Square, Bangkok, Thailand, Publisher:
        Springer, Singapore DOI: 10.1007/978-981-15-5224-3_19
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Bijoy Goswami, Kousik Naskar, Sharmistha Shee kanra, Anup Day, Abir Jana
        and Subir Kumar Sarkar “The Hypothesis of Two-Sources on TFET’s
        Ambipolar Current and its Quantum Confinement Effect” , 2020 8th
        International Electrical Engineering Congress (iEECON-2020) ,4-6 March
        2020, Chiang Mai, Thailand, Publisher: IEEE ,
        DOI:10.1109/iEECON48109.2020.229576
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Bijoy Goswami, Lavlesh Kumar Barman , Abir Jana , Anup Day , Wasim Reja
        and Subir Kumar Sarkar, “A Source Pocket Doping in PNPN-DG TFET to
        Preclude Ambipolar Current– Two Dimensional Simulation” , 2020 8th
        International Electrical Engineering Congress (iEECON-2020) ,4-6 March
        2020, Chiang Mai, Thailand. Publisher: IEEE, DOI:
        10.1109/iEECON48109.2020.229575
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Bijoy Goswami , Ayan Bhattacharya , Komal Kumari , Priyadarshini Das ,
        Wasim Reja and Subir Kumar Sarkar, “A Novel Double Source TFET for Low
        Power Application” 14th Conference on Industrial and Information Systems
        (ICIIS), 18-20 Dec. 2019, Kandy, Sri Lanka, Publisher: IEEE , DOI:
        10.1109/ICIIS47346.2019.9063290, Print on Demand(PoD) ISSN: 2164-7011.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Bijoy Goswami , Debadipta Basak , Koelgeet Kaur , Ayan Bhattacharya ,
        Sutanni Bhowmick and Subir Kumar Sarkar , “Reduction of Ambipolar
        Conduction in Centrally Aligned PNPN-DG TFET”, 16th International
        Conference on Electrical Engineering/Electronics, Computer,
        Telecommunications and Information Technology (ECTI-CON), 10-13 July
        2019, Pattaya, Chonburi, Thailand, Publisher: IEEE , DOI:
        10.1109/ECTI-CON47248.2019.8955417
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          P. Saha, C. Prakash, M.Sen “Highly-sensitive high-Q discontinuous
          slotted photonic crystal nanobeam cavity for gas sensing” SPIE
          Photonics West, 2-7 January,2019, San Francisco, USA.
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          C. Prakash, P. Saha, M.Sen “W1 photonic crystal slab waveguide as an
          ultra-compact TE-pass polarization filter” SPIE Photonics West, 2-7
          January,2019, San Francisco, USA.
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Dipanjan Sen, Savio Jay Sengupta, Abhishek Sharma, Wasim Reja, Bijoy
        Goswami &amp; Subir Kumar Sarkar, “A Novel Approach for RFID Based Smart
        EVM System”, 1st International Conference On Advances In Electrical
        Control Signal Systems (AECSS-2019)” , ITER, SOA, (d.u) Nov 8-9, 2019.
        DOIhttps://doi.org/10.1007/978-981-15-5262-5_49
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Goutika Paul, Bijoy Goswami and Subir Kumar Sarkar , “H-Shaped Double
        Gate Tunnel FET For Low Power Applications” International Conference In
        Recent Trends on Electronics &amp; Computer Science, (ICRTECS-2019)
        18th-19th March 2019, NATIONAL INSTITUTE OF TECHNOLOGY SILCHAR
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Mandira Biswas, Goutika Paul , Bijoy Goswami and Subir Kumar Sarkar ,
        “PPV Based Bottom Gate Organic Field Effect Transistor (BGOFET) ”
        International Conference In Recent Trends on Electronics &amp; Computer
        Science, (ICRTECS-2019) 18th-19th March 2019, NATIONAL INSTITUTE OF
        TECHNOLOGY SILCHAR.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Bijoy Goswami, Debadipta Basak, Ayan Bhattacharya, Koelgeet Kaur,
        Sutanni Bhowmick, Subir Kumar Sarkar “Analytical Modeling and Simulation
        of Low Power Salient Source Double Gate TFET” , Devices for Integarted
        Circuits (DevIC 2019), Kalyani University, 23-24 March,2019 , Publisher:
        IEEE, DOI: 10.1109/DEVIC.2019.8783677
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Gautam Chakraborty and Navajit Saikia, “A Survey on Automatic Speech
        Recognition with special reference to Sylheti Language”, Proceedings of
        5th International Conference on Computing for Sustainable Global
        Development, 14-16 March, 2018, DOI: not available (yet to be uploaded
        to digital library).
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          P.Saha, R.Kumari, A.K.Pradhan, M.Sen “A slotted photonic crystal ring
          resonator for refractive index sensing,” proceedings in International
          Conference on Microwave and Photonics (ICMAP) 2018, IIT (ISM) Dhanbad.
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Prasenjit Saha , Bijoy Goswami, “Simulation Modelling and Study on the
        Impacts of Substrate Concentration and Gate Work Function in MOSFET
        having Doped-Pocket Substrate” 2018 International Conference on
        Computing, Power and Communication Technologies (GUCON). DOI:
        10.1109/GUCON.2018.8675078, Publisher: IEEE
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Disha Bhattacharjee, Bijoy Goswami, Dinesh Kumar Dash, Ayan
        Bhattacharya, Subir Kumar Sarkar “Influence of Drain Doping Engineering
        in Triple Splitted-Drain TFET model” International Conference on Recent
        innovations in Electrical, Electronics &amp; Communication Engineering
        -ICRIEECE”. July 27th and 28th 2018, School of Electrical Engineering,
        Kalenga institute of Industrial Technology (KIIT), Bhubaneswar, India.
        Publisher: IEEE
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Bijoy Goswami, Disha Bhattacharjee, Dinesh Kumar Dash, Ayan
        Bhattacharya, Subir Kumar Sarkar “Drain Doping Engineering and its
        influence on Device Output Characteristics and Ambipolar Conduction on a
        Splitted-Drain TFET model” International Conference in Communication,
        Devices and Networking (ICCDN 2018) Sikkim Manipal Institute of
        Technology, Majhitar, Sikkim, 2-3rd June 2018.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Prasenjit Saha ,Bijoy Goswami ,“Implementation of a Doped-Pocket Region
        in order to Enhance the Device Performance of MOSFET” International
        Conference in Communication, Devices and Networking (ICCDN 2018) Sikkim
        Manipal Institute of Technology, Majhitar, Sikkim, 2-3rd June 2018.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Bijoy Goswami, Disha Bhattachariee, Dinesh Kumar Dash, Ayan
        Bhattacharya, Subir Kumar Sarkar “Demonstration of T-Shaped Channel
        Tunnel Field-Effect Transistors” 2018 2nd International Conference on
        Electronics, Materials Engineering &amp; Nano-Technology (IEMENTech),
        4-5 May 2018, Publisher: IEEE, DOI: 10.1109/IEMENTECH.2018.8465213
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Rashi Borgohain, 7et al., “NO2 sensing at room temperature using ZnO
        nanorods on graphene”, International Conference on Advances in
        Nanotechnology (ICAN), 2017
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Gautam Chakraborty, Mridusmita Sharma, Navajit Saikia and Kandarpa Kumar
        Sarma, “Recurrent Neural Network Based Approach To Recognise Isolated
        Digits In Sylheti Language Using MFCC Features”, in Proceedings of
        International Conference On Telecommunication, Power Analysis And
        Computing Techniques(ICTPACT) – 2017, ISBN: 978-1-5090-3381-2, 6th – 8th
        April 2017, DOI: not available (yet to be uploaded to digital library).
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        S. Sarma, F. Begum and A. Dandapat, “Design of a 2.5 GS/s 6-bit 2.4 mW
        Flash ADC in 45-nm CMOS Technology,” 2017 International Conference on
        Inventive Computation Technologies, ICICT 2017.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Maheshkumar H Kolekar, Nishant Bharti, Priti N Patil, “Detection of
        fence climbing using activity recognition by Support Vector Machine
        classifier”, 2016 IEEE Region 10 Conference (TENCON), 22-25 Nov. 2016,
        DOI: 10.1109/TENCON.2016.7848029., Singapore.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Amlan Jyoti Das and Navajit Saikia, “Pedestrian Detection using Dense
        LDB descriptor combined with HOG”, in International Conference on
        Information Technology (InCITe), Oct, 2016, DOI:
        10.1109/INCITE.2016.7857635.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          S. Misra, T. K. Das, P.Saha et al, “Comparison of MFCC and LPCC for a
          fixed phrase speaker verication system, time complexity and failure
          analysis", IEEE International Conference Communication Systems and
          Network Technologies (CSNT-2015).
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          S. P. Choudhury, T. K. Das, R. H. Laskar, P. Saha, U. Baruah,
          “Comparative Analysis of Two Different System's Framework for Text
          Dependent Speaker Verification", IEEE International Conference
          Circuit, Power And Computing Technologies (ICCPCT - 2015), 19th - 20th
          March 2015, Tamilnadu, India.
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          S. Mishra, T. K. Das, S. P. Choudhury, R. H. Laskar, U. Baruah, P.
          Saha, “Choosing Optimal Value for Fuzzy Membership in FCM Algorithm
          for LP-Residual Input Features”, Elsevier International Conference on
          Communication Network Technologies (ICCN-2015), 21-23rd Aug. 2015,
          Bangalore, India.
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Gunajit Kalita and Navajit Saikia, “Reversible Comparator Circuit Using
        a New Reversible Gate”, in Proceedings of the Sixth International
        Conference on Computer and Communication Technology, 2015, pp. 419-423,
        10.1145/2818567.2818685.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Navajit Saikia and Prabin K. Bora, “Perceptual Hashing in the 3D-DWT
        Domain”, in International Conference on Green Computing and Internet of
        Things (ICGCIoT), Oct, 2015, DOI: 10.1109/ICGCIoT.2015.7380552.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Ranjita Das, Rashi Borgohain, et al. "Study on sheet resistance
        variation in ZnO nanorod arrays upon exposure to LPG at room
        temperature."&nbsp;Energy, Power and Environment: Towards Sustainable
        Growth (ICEPE), 2015 International Conference on. IEEE, 2015.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Niranjan Jyoti Borah, “Performance Analysis of DG FD Metal Gate SOI
        MOSFET with High-K Gate Dielectric”, 4th International Conference on
        “Emerging Trends in Engineering and Technology, 24-25 April 2015 by
        College of Engineering, Teerthanker Mahaveer University Moradabad
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Navajit Saikia and Prabin K. Bora, “Video Authentication Using Temporal
        Wavelet Transform”, in 15th International Conference on Advanced
        Computing and Communications, Dec, 2007, DOI: 10.1109/ADCOM.2007.115
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        A. B. Kalita, S. Sharma, and R. Borgohain "Conical MOSFET: A Novel
        Device Geometry for Surrounding Gate MOSFET" International Conference on
        Recent Trends on Nanoscience and Technology (ICRTNT 06), Jadavpur
        University,7th-9th Dec. 2006.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        J. C. Dutta, S. Sharma, and R. Borgohain " Mixed Domain Modelling and
        Simulation of nano size ISFET for Bio-electronic device" Proc. of
        ICRTNT-06, Jadavpur University, India, 7th -9th Dec. 2006.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Prabin K. Bora and Navajit Saikia, “Retrieving Video in Wavelet
        Compressed Domain”, in 8th International Conference on Information
        Technology, Dec, 2005, Bhubaneswar, DOI: not available.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Prabin K. Bora and Navajit Saikia, “ Image Retrieval Using One-Sided
        Linear Prediction Based Texture Modelling”, in International Conference
        on Computer Vision, Graphics, and Image Processing, Dec, 2002, DOI: not
        available.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Ananya Choudhuryand Kandarpa Kumar Sarma
        <span lang="en-GB">
          ,“A Framework for Segmentation of Characters and Words from In-Air
          Handwritten Assamese Text”,In: Mohanty, M.N., Das, S. (eds)&nbsp;
        </span>
        <span lang="en-GB">
          <em>Advances in Intelligent Computing and Communication</em>
        </span>
        <span lang="en-GB">
          . Lecture Notes in Networks and Systems, pp. 235-246, vol 430.
          Springer, Singapore, 2022.&nbsp;
        </span>
        <span lang="en-GB">
          <u>DOI: https://doi.org/10.1007/978-981-19-0825-5_25</u>
        </span>{" "}
        (SCOPUS indexed)
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Ananya Choudhuryand Kandarpa Kumar Sarma
        <span lang="en-GB">&nbsp;(2021)</span>, “A Two Stage Framework for
        Detection and Segmentation of Writing Events in Air-Written Assamese
        Characters”. In: Deka B., Maji P., Mitra S., Bhattacharyya D., Bora P.,
        Pal S. (eds){" "}
        <em>Pattern Recognition and Machine Intelligence. PReMI</em> 2019.
        Lecture Notes in Computer Science, vol 11941. Springer.{" "}
        <u>DOI: https://doi.org/10.1007/978-3-030-34869-4_63</u> (SCOPUS
        indexed)
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Ananya Choudhuryand Kandarpa Kumar Sarma
        <span lang="en-GB">
          , "A Novel Approach for Gesture Spotting in an Assamese Gesture-Based
          Character Recognition System using a Unique Geometrical Feature
          Set,"&nbsp;
        </span>
        <span lang="en-GB">
          <em>
            Proceedings of IEEE 5th International Conference on Signal
            Processing and Integrated Networks,
          </em>
        </span>
        <span lang="en-GB">pp. 98-104, Noida, 2018,&nbsp;</span>
        <span lang="en-GB">
          <u>DOI:&nbsp;</u>
        </span>
        <a href="https://doi.org/10.1109/SPIN.2018.8474285" target="_blank">
          <span lang="en-GB">10.1109/SPIN.2018.8474285</span>
        </a>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Ananya Choudhury<span lang="en-GB">, A. K. Talukdar&nbsp;</span>
        <span lang="en-GB">
          and K. K. Sarma, “A Conditional Random Field based Indian Sign
          Language Recognition System under Complex Background”,&nbsp;
        </span>
        <span lang="en-GB">
          <em>Proceedings of IEEE Fourth&nbsp;</em>
        </span>
        <span lang="en-GB">
          <em>
            International Conference on Communication Systems and Network
            Technologies (CSNT)
          </em>
        </span>
        <span lang="en-GB">
          , pp. 900 – 904, Bhopal, India, April 2014,&nbsp;
        </span>
        <span lang="en-GB">
          <u>DOI:&nbsp;</u>
        </span>
        <a href="http://dx.doi.org/10.1109/CSNT.2014.185" target="_blank">
          10.1109/CSNT.2014.185
        </a>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Ananya Choudhury<span lang="en-GB">, A. K. Talukdar&nbsp;</span>
        <span lang="en-GB">
          and K. K. Sarma, “A Novel Hand Segmentation Method for Multiple-Hand
          Gesture Recognition System under Complex Background”,&nbsp;
        </span>
        <span lang="en-GB">
          <em>
            Proceedings of IEEE International Conference on Signal Processing
            and Integrated Networks (SPIN)
          </em>
        </span>
        <span lang="en-GB">
          , pp. 136-140, Noida, Delhi-NCR, India, February 2014,&nbsp;
        </span>
        <span lang="en-GB">
          <u>DOI:&nbsp;</u>
        </span>
        <a href="https://doi.org/10.1109/SPIN.2014.6776936" target="_blank">
          <span lang="en-GB">10.1109/SPIN.2014.6776936</span>
        </a>
      </p>
    </li>
  </ol>
  <p
    style={{
      marginLeft: "0cm",
      marginBottom: "0cm",
      lineHeight: "150%",
      textAlign: "justify",
      background: "transparent",
      fontFamily: '"Calibri", serif',
      fontSize: 16
    }}
  >
    <br />
  </p>
  <p
    style={{
      marginLeft: "0cm",
      marginBottom: "0cm",
      lineHeight: "150%",
      textAlign: "justify",
      background: "transparent",
      fontFamily: '"Calibri", serif',
      fontSize: 16
    }}
  >
    <br />
  </p>
  <p
    style={{
      marginLeft: "0cm",
      marginBottom: "0cm",
      lineHeight: "150%",
      textAlign: "justify",
      background: "transparent",
      fontFamily: '"Calibri", serif',
      fontSize: 16
    }}
  >
    <br />
  </p>
  <p
    style={{
      marginLeft: "0cm",
      marginBottom: "0cm",
      lineHeight: "150%",
      textAlign: "justify",
      background: "transparent",
      fontFamily: '"Calibri", serif',
      fontSize: 16
    }}
  >
    <strong>National Conference</strong>
  </p>
  <p
    style={{
      marginLeft: "0cm",
      marginBottom: "0cm",
      lineHeight: "150%",
      textAlign: "justify",
      background: "transparent",
      fontFamily: '"Calibri", serif',
      fontSize: 16
    }}
  >
    <br />
  </p>
  <ol>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Ankur Jyoti Sarmah,KabindraBhagawati, KaustovDuwarah, Divika
          Muchahary, “Object detection and conversion of text to speech for
          visually impaired” September 2023 ADBU Journal of Engineering
          Technology Guwahati.&nbsp;
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          Ankur Jyoti Sarmah, Nitin Bhattacharyya, Ritik Kumar Jain,
          PriyankarMaroti, “Emotion Recognition Through Facial Expressions Using
          CNN”{" "}
          <span style={{ background: "#ffffff" }}>
            International Journal of Engineering Research and Applications, SSN:
            2248-9622, Vol. 13, Issue 2, February 2023, pp. 41-48,
          </span>{" "}
          February 2023, DOI: 10.9790/9622-13024148
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Hardik Das, Shruti Goswami, Namrata Borah, Rashi Borgohain,” IoT Based
        Water Supply Monitoring and Notification System”, National Conference on
        Recent Trends in Electronics and Communication Engineering – 2020 on
        14/08/2020
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Ankur Jyoti Sarmah, Samujjal Das, Bhashyab Jyoti Deka, Himshikhar Gayan,
        Mrinmoy Kalita, “Implementation of smart city aspects using IoT”,
        National Conference on Recent Trends in Electronics and Communication
        Engineering, Nandyal, August, 2020. ISBN: 978-93-87113-22-0.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Sharmistha Shee Kanrar, Bijoy Goswami, Abir Jana, Subir Kumar Sarkar,
        “An Insight into Quantization Effects in Ultra-Thin Tunnel Field Effect
        Transistor” MEMs based Sensors and Smart Nanostructured Devices (MSSND
        -2019), 27th-28th Dec, 2019, Jadavpur University.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Anup Dey, Subhashis Roy, Sourav Guha and Bijoy Goswami, “Influence of Pd
        Nanoparticle in Improving the Sensitivity Of Sol-Gel Grown
        Nanocrystalline N-Wo3 Thin Film Based Hydrogen Sensor”, MEMs based
        Sensors and Smart Nanostructured Devices (MSSND -2019), 27th-28th Dec,
        2019, Jadavpur University.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Bikram Biswas, Anup Dey, Bijoy Goswami, Subhashis Roy, Sutannti Bhowmick
        and Abir Jana, “ IoT-Based Smart Heart-Health Monitoring System”, MEMs
        based Sensors and Smart Nanostructured Devices (MSSND -2019), 27th-28th
        Dec, 2019, Jadavpur University.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Shivam Dutta, D. Venkata Sai Joga Rao, S. Hemanth Kumar, Nishant Bharti
        “An embedded system to display and control of air quality inside a
        public transport” MEMs based Sensors and Smart Nanostructured Devices
        (MSSND -2019), 27th-28th Dec, 2019, Jadavpur University.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Simantika Choudhury, Navajit Saikia and Amlan Jyoti Das, “Recent Trends
        in Learning Based Techniques for Human and Animal Detection” in Joint
        National Conference on Emerging Computing Technologies &amp; its
        Applications (NCECTA), April, 2019, PSG College of Technology,
        Coimbatore, Tamil Nadu, India.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Navajit Saikia and Prabin K. Bora, “Robust Video Hashing Using the
        3D-DWT”, in National Conference on Communications (NCC), Jan, 2011, DOI:
        10.1109/NCC.2011.5734750.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Navajit Saikia and Prabin K. Bora, “A Hybrid Algorithm for Video
        Authentication”, in National Conference on Communications, 2008, DOI:
        not available.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        R. Borgohain, S. Sharma, and J. C. Dutta "Modelling Cylindrical nano
        size ISFET for Biosensor applications" Proc. of CODEC-06, Kolkata
        University, India, Dec. 2006.
      </p>
    </li>
  </ol>
  <p
    style={{
      marginLeft: "0cm",
      marginBottom: "0cm",
      lineHeight: "150%",
      textAlign: "justify",
      background: "transparent",
      fontFamily: '"Calibri", serif',
      fontSize: 16
    }}
  >
    <br />
  </p>
  <p
    style={{
      marginLeft: "0cm",
      marginBottom: "0cm",
      lineHeight: "150%",
      textAlign: "justify",
      background: "transparent",
      fontFamily: '"Calibri", serif',
      fontSize: 16
    }}
  >
    <strong>Book Chapters</strong>
  </p>
  <ol>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          <span lang="en-IN">
            Mr. Ayan Bhattacharya, Ankur Jyoti Sarmah, Manash Hazarika, Dr.
            Nalin Bihari Dev Choudhury and Dr. Bijoy Goswami,&nbsp;
          </span>
          “Dielectrically Modulated Double Gate Source-Drain Engineered TFET
          Based Label-Free Biosensor” Microelectronics: Simulations, Modeling,
          and Applications,” to be published by Wiley and Scrivener (accepted,{" "}
          <span style={{ background: "#ffffff" }}>under printing</span>).
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          <span style={{ background: "#ffffff" }}>
            Anupal Deuri Bharali, Bhupali Sarma, Ritul Paul, Tushar Nath, Mrs.
            Riju Kalita, Dr. Bijoy Goswami “Dual Source SOI TFET for Inverter
            Application” Nova publication. (accepted, under printing)
          </span>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        <span size={3} style={{ fontSize: 16 }}>
          <span style={{ background: "#ffffff" }}>
            <a href="https://onlinelibrary.wiley.com/authored-by/Barman/Pranjal">
              Pranjal Barman
            </a>
            ,{" "}
            <a href="https://onlinelibrary.wiley.com/authored-by/Dutta/Lachit">
              Lachit Dutta
            </a>
            ,{" "}
            <a href="https://onlinelibrary.wiley.com/authored-by/Bordoloi/Sushanta">
              Sushanta Bordoloi
            </a>
            ,{" "}
            <a href="https://onlinelibrary.wiley.com/authored-by/Sarma/Manash+Pratim">
              Manash Pratim Sarma
            </a>
            ,{" "}
            <a href="https://onlinelibrary.wiley.com/authored-by/Kalita/Anamika">
              Anamika Kalita
            </a>
            ,{" "}
            <a href="https://onlinelibrary.wiley.com/authored-by/Bharali/Swapna">
              Swapna Bharali
            </a>
            ,{" "}
            <a href="https://onlinelibrary.wiley.com/authored-by/Azzopardi/Brian">
              Brian Azzopardi
            </a>
            , "Sustainable Mobility: Clean Energy Integration with Electric
            Vehicle Technology." Sustainable Mobility: Policies, Challenges and
            Advancements (2024): 1-28. Doi:{" "}
          </span>
          <span style={{ background: "#ffffff" }}> </span>
          <a href="https://doi.org/10.1002/9781394166831.ch1">
            <strong>https://doi.org/10.1002/9781394166831.ch1</strong>
          </a>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Bijoy Goswami,Savio Jay Sengupta, Ankur Jyoti Sarmah,Nalin Behari Dev
        Choudhury, “
        <a href="https://www.researchgate.net/publication/374802138_Impact_of_Working_Temperature_on_the_I_ON_I_OFF_Ratio_of_a_Hetero_Step-Shaped_Gate_TFET_With_Improved_Ambipolar_Conduction?_sg%5B0%5D=50_q6KY34tofq8eBaJg02XCTj_LApfuE8hmPo5dZEI49Wb5S9IgjWUXZG1ihPkYPYBMcbVBVwt9rD6quxVmXBSh2gUS_DtHOjMgnF_tP.MpAH3o9FNBak4U8tE6f8TRuwUzUF6TXxLB-sS_c-nsp4j_SjMX1L71ROD8OGFx2Zavc3OReSUJOzW_SuOc00-Q&_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSIsInBvc2l0aW9uIjoicGFnZUNvbnRlbnQifX0">
          Impact of Working Temperature on the I
        </a>
        <a href="https://www.researchgate.net/publication/374802138_Impact_of_Working_Temperature_on_the_I_ON_I_OFF_Ratio_of_a_Hetero_Step-Shaped_Gate_TFET_With_Improved_Ambipolar_Conduction?_sg%5B0%5D=50_q6KY34tofq8eBaJg02XCTj_LApfuE8hmPo5dZEI49Wb5S9IgjWUXZG1ihPkYPYBMcbVBVwt9rD6quxVmXBSh2gUS_DtHOjMgnF_tP.MpAH3o9FNBak4U8tE6f8TRuwUzUF6TXxLB-sS_c-nsp4j_SjMX1L71ROD8OGFx2Zavc3OReSUJOzW_SuOc00-Q&_tp=eyJjb250ZXh0Ijp7ImZpcnN0UGFnZSI6InByb2ZpbGUiLCJwYWdlIjoicHJvZmlsZSIsInBvc2l0aW9uIjoicGFnZUNvbnRlbnQifX0">
          <sub>ON</sub> /I<sub>OFF</sub> Ratio of a Hetero Step‐Shaped Gate TFET
          With Improved Ambipolar Conduction
        </a>{" "}
        “ , Nanodevices for Integrated Circuit Design, October 2023, DOI:
        10.1002/9781394186396.ch5
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Siddhanta Borah, Ankush Kr. Gaur, J. A Valan (2021) Sensor-Based Alarm
        System for Preventing Crop Vandalization by Birds in Agricultural
        Regions. In: Hura G., Singh A., Siong Hoe L. (eds) Advances in
        Communication and Computational Technology. Lecture Notes in Electrical
        Engineering, vol 668. Springer, Singapore.{" "}
        <a href="https://doi.org/10.1007/978-981-15-5341-7_9">
          https://doi.org/10.1007/978-981-15-5341-7_9
        </a>
        .
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Bijoy Goswami, Sutanni Bhowmick, Arindam Halder, Debadipta Basak,
        Goutika Paul and Subir Kumar Sarkar, “Implementation of L-shaped Double
        Metal Dual Gate TFET towards Improved Performance Characteristics and
        Reduced Ambipolarity" Springer Nature Singapore Pte Ltd 2020, “Lecture
        Notes in Networks and Systems” DOI 10.1007/978-981- 32-9453-0_3, ISBN
        978-981-32-9452-3 (Print) , ISBN 978-981-32-9453-0 (Online)
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Dipanjan Sen, Savio Jay Sengupta, Abhishek Sharma, Wasim Reja, Bijoy
        Goswami &amp; Subir Kumar Sarkar “A Novel Approach for RFID Based Smart
        EVM System”, Springer Nature Singapore Pte Ltd. 2020, Lecture Note
        Electrical Engineering , DOI: 10.1007/978-981-15-5262-5_49
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Dipanjan Sen, Bijoy Goswami, Anup Dey and Subir Kumar Sarkar
        “Performance Evaluation of Novel Channel Engineered Junctionless Double
        Gate MOSFET for Radiation Sensing and Low Power Circuit Application”
        “Electrical and Electronic Devices, Circuits and Materials: Design and
        Applications 2020” CRC Taylor &amp;amp; Francis , DOI:{" "}
        <a href="https://doi.org/10.1201/97810030977">
          https://doi.org/10.1201/97810030977
        </a>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Abir Jana, Disha Bhattacharjee, Komal Kumari, Anup Dey, Bijoy Goswami,
        Subir Kumar Sarkar , “Designing Memristor Based Timing Circuits and
        Performance Comparison with CMOS Counterparts” Springer Nature Singapore
        Pte Ltd 2020, Smart Trends in Computing and Communications: Proceedings
        of SmartCom 2020 , DOI: 10.1007/978-981-15-5224-3_26
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Anup Dey , Komal Kumari, Abir Jana, Bijoy Goswami, Poushali Nandi and S.
        K. Sarkar “Room temperature Pt modified WO3/p-Si Film Gas Sensor for
        Detection of Methanol” Springer Nature Singapore Pte Ltd 2020, Smart
        Trends in Computing and Communications: Proceedings of SmartCom 2020.
        DOI: 10.1007/978-981-15-5224-3_19
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent"
        }}
      >
        Arnab Sarkar, Rashi Borgohain, Bikash Agarwal and Sunandan Baruah “ZnO
        Nanostructures for Environmental Applications” ZnO Nanostructures
        Advances in Research and Applications, 2020 Nova Science Publishers.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Bijoy Goswami, Disha Bhattacharjee, Dinesh Kumar Dash, Ayan Bhattacharya
        and Subir Kumar Sarkar “Drain Doping Engineering and its influence on
        Device Output Characteristics and Ambipolar Conduction on a
        Splitted-Drain TFET model” Springer Nature Singapore Pte Ltd 2019,
        “Advances in Communication, Devices and Networking” DOI
        10.1007/978-981-13-3450-4 ISBN 9789811334504 (online) 9789811334498
        (print)
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Amlan J. Das, Navajit Saikia, Kandarpa K. Sarma,&nbsp;“Object
        classification and tracking in real-time: An
        overview”&nbsp;in&nbsp;Emerging Technologies in Intelligent Applications
        for Image and Video Processing, Chapter-11, pp. 250-295,IGI Global, USA,
        2016.
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Ananya Choudhuryand Kandarpa Kumar Sarma, Visual gesture-based character
        recognition systems for design of assistive technologies for people with
        special necessities. In{" "}
        <em>
          Research Anthology on Physical and Intellectual Disabilities in an
          Inclusive Society
        </em>
        , pp. 264-285,. IGI Global, 2022,{" "}
        <u>DOI:&nbsp;10.4018/978-1-6684-3542-7.ch014</u>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Ananya Choudhuryand Kandarpa Kumar Sarma<span lang="en-GB">, “</span>
        <span lang="en-GB">
          <em>
            Visual Gesture-Based Character Recognition Systems for Design of
            Assistive Technologies for People with Special Necessities
          </em>
        </span>
        <span lang="en-GB">
          ”, Handmade Teaching Materials for Students with Disabilities, eds. S.
          Ikuta, pp. 294-315, Hershey,PA: IGI Global, Web. May, 2018,&nbsp;
        </span>
        <span lang="en-GB">
          <u>DOI: 10.4018/978-1-6684-3542-7.ch014</u>
        </span>
      </p>
    </li>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Ananya Choudhury, A. K. Talukdar and K. K. Sarma, “
        <em>
          A Review on Vision-Based Hand Gesture Recognition and Applications”
        </em>
        , In: Intelligent Applications for Heterogeneous System Modeling and
        Design, pp.261-286, Edition: 1st, Publisher: IGI Global, USA, 2015,{" "}
        <u>DOI:&nbsp;</u>
        <a
          href="http://dx.doi.org/10.4018/978-1-4666-8493-5.ch011"
          target="_blank"
        >
          10.4018/978-1-4666-8493-5.ch011
        </a>
      </p>
    </li>
  </ol>
  <p
    style={{
      marginLeft: "0cm",
      marginBottom: "0cm",
      lineHeight: "150%",
      textAlign: "justify",
      background: "transparent",
      fontFamily: '"Calibri", serif',
      fontSize: 16
    }}
  >
    <br />
  </p>
  <p
    style={{
      marginLeft: "0cm",
      marginBottom: "0cm",
      lineHeight: "150%",
      textAlign: "justify",
      background: "transparent",
      fontFamily: '"Calibri", serif',
      fontSize: 16
    }}
  >
    <strong>Books (Editor Book Chapter)</strong>
  </p>
  <ol>
    <li>
      <p
        style={{
          marginLeft: "1.52cm",
          marginBottom: "0cm",
          lineHeight: "150%",
          textAlign: "justify",
          background: "transparent",
          fontFamily: '"Calibri", serif',
          fontSize: 16
        }}
      >
        Advances in Communication and Computing – Prabin Kumar Bora, S. R.
        Mahadeva Prasanna, Kandarpa Kumar Sarma, Navajit Saikia Editors –
        Lecture Notes in Electrical Engineering – vol. 347, ISSN: 1876-1100,
        ISSN: 1876-1119 (electronic), ISBN: 978-81-322-2463-1, ISBN:
        978-81-322-2464(eBook), DOI: 10.1007/978-81-322-2462-8,
        Springer-2015&nbsp;
      </p>
    </li>
  </ol>
  <p
    style={{
      marginLeft: "0cm",
      marginBottom: "0cm",
      lineHeight: "150%",
      textAlign: "justify",
      background: "transparent"
    }}
  >
    <br />
  </p>
  <p>
    <br />
  </p>
</>




        </MDBContainer>
      </div>
    )
  }
}

export default withStyles(styles)(FacultyPublications)
